import React from 'react';
import { Container } from 'react-bootstrap';
import InvoiceRows from './invoiceRows';
import './dynamicFormField.css';

interface Props {
    inputFields: any;
    handleChangeInput: any;
    handleAddRow: any;
    handleRemoveRow: any;
    isHiddenRabat: boolean;
    isHiddenServiceDescription: boolean;
}

export default function DynamicFormField({
    inputFields,
    handleChangeInput,
    handleAddRow,
    handleRemoveRow,
    isHiddenRabat,
    isHiddenServiceDescription
}: Props) {

    return (
        <Container fluid>
            <InvoiceRows
                inputFields={inputFields}
                handleChangeInput={handleChangeInput}
                handleAddRow={handleAddRow}
                handleRemoveRow={handleRemoveRow}
                isHiddenRabat={isHiddenRabat}
                isHiddenServiceDescription={isHiddenServiceDescription}
            />
        </Container>
    );
};