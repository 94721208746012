import {
    createAsyncThunk,
    createSelector,
    createSlice,
    PayloadAction
} from "@reduxjs/toolkit";
import { sendMessage } from "../app/midenasWEBApi";
import { RootState } from "../app/store";
import EmailRequestModel from "../models/emailRequestModel";

interface EmailState {
    isMessageSend: boolean | null;
    successMessage: string;
    errorMessage: string;
};

let initialState: EmailState = {
    isMessageSend: null,
    successMessage: "",
    errorMessage: "",
};

export const sendContactForm = createAsyncThunk("email/send_contact_form", async (contactRequest: EmailRequestModel) => {
    var result = await sendMessage(contactRequest);
    return result;
});

export const selectIsMessageSend = createSelector(
    (state: RootState) => state.email,
    (email: EmailState ) => {
        return email;
    }
);

export const resetMessage = createAsyncThunk("reset_message", () => {
});

const emailSlice = createSlice({
    name: "email",
    initialState: initialState,
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(sendContactForm.fulfilled, (state, { payload }) => {
                if (payload.success) {
                    state.isMessageSend = true;
                    state.successMessage = "Uspešno ste poslali poruku";
                } else {
                    state.errorMessage = payload.errorMessage;
                }
            })
            .addCase(sendContactForm.rejected, (state, { payload }) => {
                state.isMessageSend = false;
                state.errorMessage = "Unete informacije nisu ispravne. Proverite podatke i pokušajte ponovo"
            })
            .addCase(resetMessage.fulfilled, (state, { }) => {
                state.errorMessage = "";
                state.isMessageSend = null;
                state.successMessage = "";
            })
    },
});

export default emailSlice.reducer;