import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup';
import AllInvoiceModal from '../../modals/dashboardModals/allInvoicesModal/allInvoicesModal';
import MonthModal from '../../modals/dashboardModals/monthModal/monthModal';
import WeekModal from '../../modals/dashboardModals/weekModal/weekModal';

export default function MainDashboard() {
    const { t } = useTranslation();

    const [allInvoicesModal, setAllInvoicesModal] = useState(false);
    const [monthInvoiceModal, setMonthInvoiceModal] = useState(false);
    const [weekInvoiceModal, setWeekInvoiceModal] = useState(false);

    const handleAllInvoicesModalOpen = () => {
        setAllInvoicesModal(true);
    };

    const handleAllInvoicesModalClose = () => {
        setAllInvoicesModal(false);
    };

    const handleMonthInvoicesModalOpen = () => {
        setMonthInvoiceModal(true);
    };

    const handleMonthInvoicesModalClose = () => {
        setMonthInvoiceModal(false);
    };

    const handleWeekInvoiceModalOpen = () => {
        setWeekInvoiceModal(true);
    };

    const handleWeekInvoiceModalClose = () => {
        setWeekInvoiceModal(false);
    };

    return (
        <div className="dashboard_cardBox">
            <div className="dashboard_card" onClick={handleAllInvoicesModalOpen}>
                <div >
                    <div
                        className="counter dashboard_numbers"
                        data-target="500"
                    >
                        <CountUp end='200' duration={2} />
                    </div>
                    <div className="dashboard_cardName" >
                        {t('mainDashboard.allInoviceCard')}
                    </div>
                </div>
                <div className="dashboard_iconBx" >
                    <i className="fas fa-file-invoice"></i>
                </div>
            </div>
            <div
                className="dashboard_card"
                onClick={handleMonthInvoicesModalOpen}
            >
                <div>
                    <div className="dashboard_numbers">
                        <CountUp end='34' duration={2} />
                    </div>
                    <div className="dashboard_cardName">{t('mainDashboard.monthInvoiceCard')}</div>
                </div>
                <div className="dashboard_iconBx">
                    <i className="fas fa-calendar-alt"></i>
                </div>
            </div>
            <div
                className="dashboard_card"
                onClick={handleWeekInvoiceModalOpen}
            >
                <div>
                    <div className="dashboard_numbers">
                        <CountUp end='98' duration={2} />
                    </div>
                    <div className="dashboard_cardName">{t('mainDashboard.weekInvoiceCard')}</div>
                </div>
                <div className="dashboard_iconBx">
                    <i className="fas fa-calendar-week"></i>
                </div>
            </div>
            <AllInvoiceModal
                show={allInvoicesModal}
                onHide={() => setAllInvoicesModal(false)}
                modalClose={handleAllInvoicesModalClose}
            />
            <MonthModal
                show={monthInvoiceModal}
                onHide={() => setMonthInvoiceModal(false)}
                modalClose={handleMonthInvoicesModalClose}
            />
            <WeekModal
                show={weekInvoiceModal}
                onHide={() => setWeekInvoiceModal(false)}
                modalClose={handleWeekInvoiceModalClose}
            />
        </div>
    );
};