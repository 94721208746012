/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import './dynamicFormField.css';
import CustomSelect from '../customSelect/customSelect';

interface Props {
    inputFields: any;
    handleChangeInput: any;
    handleAddRow: any;
    handleRemoveRow: any;
    isHiddenRabat: boolean;
    isHiddenServiceDescription: boolean;
};

const nameOfServiceOptions = [
    { value: 'service1', label: 'Pružanje usluga zaposlenog Davor Pajić po Ugovoru o poslovnotehničkoj saradnji zaključen dana 01.10.2021.' },
    { value: 'service2', label: 'Service 2' },
    { value: 'service3', label: 'Service 3' },
];
const serviceDescriptionOptions = [
    { value: 'service1', label: 'Pružanje usluga zaposlenog Davor Pajić po Ugovoru o poslovnotehničkoj saradnji zaključen dana 01.10.2021.' },
    { value: 'service2', label: 'Service 2' },
    { value: 'service3', label: 'Service 3' },
];

export default function InvoiceRows({
    inputFields,
    handleChangeInput,
    handleAddRow,
    handleRemoveRow,
    isHiddenRabat,
    isHiddenServiceDescription
}: Props) {
    const { t } = useTranslation();

    return (
        <div>
            <Table responsive className="table table-striped invoiceRows_table">
                <thead>
                    <tr>
                        <th scope="col">{t('smartInvoicing.no')}</th>
                        <th scope="col">{t('smartInvoicing.nameOfService')}</th>
                        <th scope="col" className={classNames('', { 'dynamicFormField_hidde': isHiddenServiceDescription })}
                        >
                            {t('smartInvoicing.serviceDescription')}
                        </th>
                        <th scope="col">{t('smartInvoicing.unitOfMeasure')}</th>
                        <th scope="col">{t('smartInvoicing.quantity')}</th>
                        <th scope="col">{t('smartInvoicing.price')}</th>
                        <th scope="col" className={classNames('', { 'dynamicFormField_hidde': isHiddenRabat })}
                        >
                            {t('smartInvoicing.rabat')}
                        </th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    {inputFields.map((addInvoiceInput: any, index: any) => (
                        <tr>
                            <th
                                key={index}
                                scope="row"
                                style={{ width: "5%" }}
                            >
                                <Form.Label
                                    key={index}
                                    style={{
                                        fontSize: '18px',
                                        color: '#5a5a5a',
                                        alignSelf: 'center',
                                        alignContent: 'center'
                                    }}
                                >
                                    {addInvoiceInput.RowNumber}
                                </Form.Label>
                            </th>
                            <td
                                style={{ width: '35%' }}
                            >
                                <CustomSelect
                                    options={nameOfServiceOptions}
                                    placeholder={t('placeholder.chooseServiceName')}
                                />
                            </td>
                            <td
                                className={classNames('', { 'dynamicFormField_hidde': isHiddenServiceDescription })}
                                style={{ width: '35%' }}
                            >
                                <Col>
                                    <CustomSelect
                                        options={serviceDescriptionOptions}
                                        placeholder={t('placeholder.chooseServiceDescription')}
                                    />
                                </Col>
                            </td>
                            <td style={{ width: '5%' }} >
                                <Form.Group>
                                    <Form.Control
                                        name="UnitOfMeasure"
                                        type='text'
                                        value={addInvoiceInput.UnitOfMeasure}
                                        onChange={(e) => handleChangeInput(index, e)}
                                    />
                                </Form.Group>
                            </td>
                            <td style={{ width: '5%' }}>
                                <Form.Group>
                                    <Form.Control
                                        name="Quantity"
                                        type='text'
                                        value={addInvoiceInput.Quantity}
                                        onChange={(e) => handleChangeInput(index, e)}
                                    />
                                </Form.Group>
                            </td>
                            <td
                                style={{ width: '8%' }}
                            >
                                <Form.Group>
                                    <Form.Control
                                        className="invoiceRow_price"
                                        name="Price"
                                        type='text'
                                        value={addInvoiceInput.Price}
                                        onChange={(e) => handleChangeInput(index, e)}
                                    />
                                </Form.Group>
                            </td>
                            <td style={{ width: '5%' }}>
                                <Col sm className={classNames('', { 'dynamicFormField_hidde': isHiddenRabat })}>
                                    <Form.Group>
                                        <Form.Control
                                            className="invoiceRow_rabat"
                                            name="Rabat"
                                            type='text'
                                            value={addInvoiceInput.Rabat}
                                            onChange={(e) => handleChangeInput(index, e)}
                                        />
                                    </Form.Group>
                                </Col>
                            </td>
                            <td style={{
                                background: "white",
                                verticalAlign: 'middle',
                            }}
                            >
                                {index !== 0 && (
                                    <i
                                        className="fas fa-trash dynamicField_icon-remove"
                                        onClick={(e) => handleRemoveRow(e, index)}
                                    />
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <OverlayTrigger
                placement="bottom"
                overlay={
                    <Tooltip id="tooltip-disabled">
                        {t('invoiceRows.addRow')}
                    </Tooltip>
                }
            >
                <a href="#">
                    <IoIosAddCircleOutline
                        name="dynamicForm_addRow"
                        className="dynamicField_icon-add"
                        onClick={(e) => handleAddRow(e)}
                    />
                </a>
            </OverlayTrigger>
        </div>
    );
};