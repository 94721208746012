export default function getStringDateWithFormat(date: Date, format = "datetime") {
    if (date) {
        var d = new Date(date)
        let month = "" + (d.getMonth() + 1);
        let day = "" + (d.getDate())
        let year = d.getFullYear()
        let hours = "" + (d.getHours())
        let minutes = "" + (d.getMinutes())
        let seconds = "" + (d.getSeconds())
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        var result;
        switch (format) {
            case ('date'):
                result = `${day}-${month}-${year}`;
                break;
            case ('time'):
                result = `${hours}:${minutes}:${seconds}`;
                break;
            default:
                result = `${year}-${month}-${day}
                ${hours}:${minutes}:${seconds}`;
                break;
        }
        return result.replace(/(\r\n|\n|\r)/gm, "").replace(/\s+/g, " ");
    } else {
        return '';
    }

};