import React from 'react';
import { Accordion, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function MenageTableDataAccordion() {
    const { t } = useTranslation();
    return (
        <>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>{t('menageTableAccordion.nameOfService')}</Accordion.Header>
                    <Accordion.Body>
                        <Form.Group>
                            <Form.Label>{t('menageTableAccordion.title')}</Form.Label>
                            <Form.Control
                                name="NameOfService_title"
                                style={{ resize: "none" }}
                                type='text'
                            />
                        </Form.Group>
                        <Form.Group className="mt-4"
                        >
                            <Form.Label>{t('menageTableAccordion.description')}</Form.Label>
                            <Form.Control
                                name="NameOfService_accordion"
                                style={{ resize: "none" }}
                                as="textarea"
                                rows={4}
                                type='text'
                            />
                        </Form.Group>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>{t('menageTableAccordion.serviceDescription')}</Accordion.Header>
                    <Accordion.Body>
                        <Form.Group>
                            <Form.Label>{t('menageTableAccordion.title')}</Form.Label>
                            <Form.Control
                                name="ServiceDescription_title"
                                style={{ resize: "none" }}
                                type='text'
                            />
                        </Form.Group>
                        <Form.Group className="mt-4"
                        >
                            <Form.Label>{t('menageTableAccordion.description')}</Form.Label>
                            <Form.Control
                                name="ServiceDescription_accordion"
                                style={{ resize: "none" }}
                                as="textarea"
                                rows={4}
                                type='text'
                            />
                        </Form.Group>
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>{t('menageTableAccordion.unitOfMeasure')}</Accordion.Header>
                    <Accordion.Body>
                        <Form.Group>
                            <Form.Label>{t('menageTableAccordion.title')}</Form.Label>
                            <Form.Control
                                name="UnitOfMeasure_title"
                                style={{ resize: "none" }}
                                type='text'
                            />
                        </Form.Group>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </>
    );
};