import { createTheme, Theme } from '@material-ui/core/styles'
// import { rsRS } from "@material-ui/core/locale";
//import { useTranslation } from "react-i18next";

export default function AppTheme(): Theme {
    //const { t } = useTranslation(["rs", "theme"]);

    const theme = createTheme(
        //     {
        //         palette: {
        //             primary: {
        //                 main: "#536878",
        //                 dark: "#36454f",
        //             },
        //             secondary: {
        //                 main: "#708090",
        //             },
        //         },
        //         spacing: 8,
        //     }
        //     // rsRS
    );

    // theme.typography.fontSize = 16;

    // theme.typography.h4 = {
    //     fontSize: "1.2rem",
    //     "@media (min-width:600px)": {
    //         fontSize: "1.5rem",
    //     },
    //     [theme.breakpoints.up("md")]: {
    //         fontSize: "2rem",
    //     },
    // };

    // theme.typography.h6 = {
    //     fontSize: "1rem",
    //     "@media (min-width:600px)": {
    //         fontSize: "1.2rem",
    //     },
    //     [theme.breakpoints.up("md")]: {
    //         fontSize: "1.5rem",
    //     },
    // };

    // theme.typography.subtitle1 = {
    //     fontSize: "0.4rem",
    //     "@media (min-width:600px)": {
    //         fontSize: "0.64rem",
    //     },
    //     [theme.breakpoints.up("md")]: {
    //         fontSize: "1rem",
    //     },
    // };

    // theme.typography.body1 = {
    //     fontSize: "0.6rem",
    //     "@media (min-width:740px)": {
    //         fontSize: "0.8rem",
    //     },
    //     [theme.breakpoints.up("md")]: {
    //         fontSize: "0.9rem",
    //     },
    // };

    return theme;
}
