import React from 'react';
import { 
    DialogActions, 
    Grid, 
    Typography, 
    Button,
    Dialog, 
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import webServices from '../../../images/webServices.png';
import { useTranslation } from 'react-i18next';
import './webServicesModal.css';

interface Props {
    open: any;
    modalClose: any;
}

export default function WebServicesModal({
    open,
    modalClose
}: Props) {
    const {t} = useTranslation();

    return (
        <div>
            <Dialog
                style={{ borderRadius: 2 }}
                open={open}
                onClose={modalClose}
                aria-labelledby="webServices_dialog-title"
                aria-describedby="webServices_dialog-description"
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="webServices_modal_title"
                    >
                        <Grid
                            item
                            xs={1}
                            sm={1}
                        >
                            <img
                                src={webServices}
                                alt=""
                                className="webServices_modal_logo_img"
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <DialogTitle
                                id="webServices_dialog-title"
                                className="webServices_dialog_title"
                            >
                                <b>{t('homePage.webServicesTitle')}</b>
                            </DialogTitle>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid
                            item
                            sm={12}
                        >
                            <DialogContentText
                                id="webServices_dialog-description"
                                className="webServices_dialog_content"
                            >
                                {t('homePage.webServicesGeneral')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="webServices_modal_typography"
                            >
                                {t('homePage.webServicesIsGoogleWebServiceTitle')}
                            </Typography>
                            <DialogContentText
                                id="webServices_dialog-description"
                                className="webServices_dialog_content"
                            >
                                {t('homePage.webServicesIsGoogleWebServiceText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="webServices_modal_typography"
                            >
                                {t('homePage.webServicesWhichLanguageTitle')}
                            </Typography>
                            <DialogContentText
                                id="webServices_dialog-description"
                                className="webServices_dialog_content"
                            >
                               {t('homePage.webServicesWhichLanguageText')}
                            </DialogContentText>
                            <DialogActions className="webServices_modal_dialogActions">
                                <Button
                                    variant="outlined"
                                    className="webServices_modal_button"
                                    onClick={modalClose}
                                >
                                    {t('buttons.close')}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </Dialog>
        </div >
    );
}