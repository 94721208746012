import React from 'react';
import {
    makeStyles,
    TextField,
    Theme,
    createStyles,
    Paper
} from '@material-ui/core';
import {
    Row,
    Col, 
    Container,
    Form,
    Modal
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import '../../../features/addDocument/addDocument.css';
import './passwordChange.css'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100%',
            },
        },
        input: {
            width: "30%"
        },
        paper: {
            borderRadius: 20,
        }
    }),
);

export default function PasswordChange() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Container fluid="xxl">
            <Paper elevation={3} className={classes.paper}>
                <form noValidate autoComplete="off">
                    <div className="dashboard_details-profile">
                        <div className="addInvoice_container">
                            <div className="addInvoice_title">{t('passwordChange.passwordChangeTitle')}</div>
                            <Row className="mt-4 text-center">
                                <Col sm>
                                    <Form.Label><h5>{t('passwordChange.updatePassword')}</h5></Form.Label>
                                </Col>
                            </Row>
                            <Row className="mt-4 text-center passwordChange_input-box">
                                <Col md>
                                    <Form.Group
                                    >
                                        <TextField className={classes.input} id="standard-basic" label={t('passwordChange.oldPassword')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4 text-center">
                                <Col sm>
                                    <Form.Group
                                    >
                                        <TextField className={classes.input} id="standard-basic" label={t('passwordChange.newPassword')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4 text-center">
                                <Col sm>
                                    <Form.Group
                                        className="text-center"
                                    >
                                        <TextField className={classes.input} id="standard-basic" label={t('passwordChange.confirmPassword')} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col sm>
                                    <Modal.Footer className="allInvoices_dashboard-footer">
                                        <button
                                            className="passwordChange_clearButton"
                                        >
                                            {t('buttons.clear')}
                                        </button>
                                        <button
                                            className="passwordChange_saveButton"
                                        >
                                            {t('buttons.save')}
                                        </button>
                                    </Modal.Footer>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </form>
            </Paper>
        </Container >
    );
};