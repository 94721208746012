import { useContext } from 'react';
import { AccountContext } from './accountContext';
import {
    BoxContainer,
    FormContainer,
    SubmitButton,
    Input,
} from './common';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import {
    dashboard as dashboardRoute
}
    from "../../app/routes";
import './loginForm.css';

export function LoginForm(props: any) {
    const { t } = useTranslation();
    const history = useHistory();
    const { switchToSignup } = useContext(AccountContext);

    const handleDashboard = () => {
        history.push(dashboardRoute())
    };

    return (
        <BoxContainer>
            <FormContainer>
                <Input type="email" placeholder={t('logIn.inputEmail')} />
                <Input type="password" placeholder={t('logIn.inputPassword')} />
            </FormContainer>
            <Form.Text
                className="loginForm_text-muted"
            >
                {t('logIn.forgetPass')}
            </Form.Text>
            <SubmitButton onClick={handleDashboard} type="submit">{t('logIn.logInBtn')}</SubmitButton>
            <Form.Text className=" loginForm_text-muted">{t('logIn.mutedLink')}
                <Form.Text className="muted_bold" onClick={switchToSignup}>
                    {t('signUp.signUpBtn')}
                </Form.Text>
            </Form.Text>
        </BoxContainer>
    );
};