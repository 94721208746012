import React, { useEffect, useState } from 'react';
import Layout from "./app/Layout";
import Routing from './app/Routing';
import './App.css';
import { useSelector } from 'react-redux';
import { selectSnackbar } from './slices/snackbarSlice';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

function App() {

  const snackbar = useSelector(selectSnackbar);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (snackbar.show) {
      setOpenSnackbar(true);
    }
  }, [snackbar])

  const handleCloseSnackbar = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };
 
  return (
    <Layout>
        <Routing />
        <Snackbar
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        autoHideDuration={3000}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default App;