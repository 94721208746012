import React, { useEffect, useState } from 'react';
import {
    Row,
    Col,
    Container,
    Form,
    Modal,
    FormControl,
    Nav
}
    from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import MenageTheColumnsModal from '../modals/addInvoiceModals/menageTheColumnsModal';
import ManageTableDataModal from '../modals/addInvoiceModals/manageTableDateModal';
import AddInvoiceRowModel from '../../models/addInvoiceRowModel';
import InvoiceModel from '../../models/invoiceModel';
import DatePickers from '../../components/datePicker/datePicker';
import DynamicFormField from '../../components/dynamicFormField/dynamicFormField';
import InvoiceComment from '../../components/dynamicFormField/invoiceComment';
import InvoiceNote from '../../components/dynamicFormField/invoiceNote';
import getStringDateWithFormat from '../../utils/dateUtilis';
import CustomSelect from '../../components/customSelect/customSelect';
import { Grid, Paper } from '@material-ui/core';
import ManageDocumentTypeModal from '../modals/addInvoiceModals/manageDocumentTypeModal';
import './addDocument.css';

const clientOptions = [
    { value: 'client1', label: 'Client 1' },
    { value: 'client2', label: 'Client 2' },
    { value: 'client3', label: 'Client 3' }
]

export default function AddInvoice() {
    const INIT_TABLE_ROWS: AddInvoiceRowModel = {
        RowNumber: 1,
        NameOfService: "",
        ServiceDescription: "",
        UnitOfMeasure: "",
        Quantity: "",
        Price: "",
        Rabat: "",
        Pdv: "",
    };

    const INIT_NEWINVOICE: InvoiceModel = {
        ClientId: "",
        Comment: "",
        CommentId: "",
        DueDate: "",
        InvoiceNumber: "",
        InvoiceTable: INIT_TABLE_ROWS,
        IssueDate: "",
        Note: "",
        NoteId: ""
    };

    const { t } = useTranslation();
    const [tableRows, setTableRows] = useState<AddInvoiceRowModel[]>([INIT_TABLE_ROWS]);
    const [newInvoice, setNewInvoice] = useState<InvoiceModel>(INIT_NEWINVOICE);
    const [menageColumnsModal, setMenageColumnsModal] = useState(false);
    const [menageTableDataModal, setMenageTableDataModal] = useState(false);
    const [menageDocumentTypeModal, setManageDocumentTypeModal] = useState(false);
    const [isHiddenRabat, setIsHiddenRabat] = useState(false);
    const [isDisabledInvoiceNumber, setIsDisabledInvoiceNumber] = useState(false);
    const [isHiddenServiceDescription, setIsHiddenServiceDescription] = useState(false);

    const handleMenageColumns = () => {
        setMenageColumnsModal(true);
    };

    const handleMenageColumnsClose = () => {
        setMenageColumnsModal(false);
    };

    const handleMenageData = () => {
        setMenageTableDataModal(true);
    };

    const handleMenageDataClose = () => {
        setMenageTableDataModal(false);
    };

    const handleMenageDocumentType = () => {
        setManageDocumentTypeModal(true);
    };

    const handleMenageDocumentTypeClose = () => {
        setManageDocumentTypeModal(false);
    };

    const handleDateOfIssueChange = (date: Date) => {
        const issueDate = getStringDateWithFormat(date);
        var newInvoiceWithDateIssue = { ...newInvoice, IssueDate: issueDate }
        setNewInvoice(newInvoiceWithDateIssue);
    };

    const handleDueDateChange = (date: Date) => {
        const dueDate = getStringDateWithFormat(date);
        var newInvoiceWithDueDate = { ...newInvoice, DueDate: dueDate }
        setNewInvoice(newInvoiceWithDueDate);
    };

    const handleChangeInput = (index: number, e: any, name: any) => {
        const value = e.target.value;
        const rowName = e.target.name;
        var newTableRows = [...tableRows];
        var row = newTableRows[index];
        var newRow = { ...row, [rowName]: value };
        newTableRows[index] = newRow;
        var invoiceChanged = newInvoice;
        invoiceChanged.InvoiceTable = newTableRows;
        setNewInvoice(invoiceChanged);
        setTableRows(newTableRows);
    };

    const handleAddRow = (e: any, index: any) => {
        var rowNumber = tableRows.length + 1;
        var newTableRows = [
            ...tableRows,
            {
                RowNumber: rowNumber,
                NameOfService: "",
                ServiceDescription: "",
                UnitOfMeasure: "",
                Quantity: "",
                Price: "",
                Rabat: "",
                Pdv: "",
            }
        ];
        var invoiceChanged = newInvoice;
        invoiceChanged.InvoiceTable = newTableRows;
        setNewInvoice(invoiceChanged);
        setTableRows(newTableRows);
    };

    const handleRemoveFields = (index: any) => {
        const values = [...tableRows];
        values.splice(index, 1);
        var rowNumber = 1;
        for (var i = 0; i < values.length; i++) {
            values[i].RowNumber = rowNumber;
            rowNumber++;
        }
        var invoiceChanged = newInvoice;
        invoiceChanged.InvoiceTable = values;
        setNewInvoice(invoiceChanged);
        setTableRows(values);
    };

    const handleCheckboxChange = (e: any) => {
        const checked = e.target.checked;
        const name = e.target.name;
        switch (checked) {
            case false:
                switch (name) {
                    case "EditInvoiceNumber":
                        setIsDisabledInvoiceNumber(false);
                        break;
                    case "ServiceDescription":
                        setIsHiddenServiceDescription(false);
                        break;
                    case "Rabat":
                        setIsHiddenRabat(false);
                        break;
                }
                break;
            case true:
                switch (name) {
                    case "EditInvoiceNumber":
                        setIsDisabledInvoiceNumber(true);
                        break;
                    case "ServiceDescription":
                        setIsHiddenServiceDescription(true);
                        break;
                    case "Rabat":
                        setIsHiddenRabat(true);
                        break;
                }
                break;
        }
    };

    const onDescriptionChange = (e: any, name: string) => {
        const value = e.target.value;
        if (name === 'Note') {
            var inv = { ...newInvoice };
            inv.Note = value;
            setNewInvoice(inv);
        } if (name === 'Comment') {
            var comm = { ...newInvoice };
            comm.Comment = value;
            setNewInvoice(comm);
        } if (name === 'InvoiceNumber') {
            var num = { ...newInvoice };
            num.InvoiceNumber = value;
            setNewInvoice(num);
        }
    };

    useEffect(() => {
        console.log(newInvoice);
    }, [newInvoice]);
    useEffect(() => {
        console.log(tableRows);
    }, [tableRows])

    return (
        <Container fluid="xxl" className="addInvoice_container">
            <Paper
                elevation={3}
            >
                <div className="addInvoice_title">
                    {t('smartInvoicing.addInvoice')}
                </div>
                <Row className="mt-4">
                    <Col sm>
                        <Grid item xs={3}>
                            <CustomSelect
                                placeholder={t('placeholder.documentType')}
                                options={null}
                            />
                        </Grid>
                        <Nav.Item>
                            <Nav.Link
                                style={{ color: "#849AA3" }}
                                onClick={handleMenageDocumentType}
                            >
                                {t('smartInvoicing.menageDocumentType')}
                            </Nav.Link>
                        </Nav.Item>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm className="addInvoice_inputGroup">
                        <Form.Label>
                            {t('smartInvoicing.invoiceNumber')}
                        </Form.Label>
                        <FormControl
                            disabled={!isDisabledInvoiceNumber}
                            className="addInvoice_invoiceNumber"
                            name="InvoiceNumber"
                            value={newInvoice.InvoiceNumber}
                            onChange={(e) => onDescriptionChange(e, 'InvoiceNumber')}

                        />
                        <Form.Group
                            className="mb-3 mt-2 addInvoice_checkbox-pdv"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Check
                                name="EditInvoiceNumber"
                                onClick={(e) => handleCheckboxChange(e)}
                            />
                            <Form.Label style={{ marginLeft: '5px' }}
                            >
                                {t('smartInvoicing.editInvoiceNumber')}
                            </Form.Label>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm>
                        <Form.Label>{t('smartInvoicing.dateOfIssue')}</Form.Label>
                        <DatePickers
                            handleDateChange={handleDateOfIssueChange}
                            selectedDate={newInvoice.IssueDate}
                        />
                    </Col>
                    <Col sm>
                        <Form.Label>{t('smartInvoicing.dueDate')}</Form.Label>
                        <DatePickers
                            handleDateChange={handleDueDateChange}
                            selectedDate={newInvoice.DueDate}
                        />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>{t('smartInvoicing.client')}</Form.Label>
                            <CustomSelect
                                options={clientOptions}
                                placeholder={t('placeholder.chooseClient')}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm className="addInvoice_checkbox-col">
                        <Form.Group
                            className="mb-3 mr-3 addInvoice_checkbox-pdv"
                            controlId="exampleForm.ControlInput1"
                        >
                            <Form.Label>{t('smartInvoicing.pdv')}</Form.Label>
                            <Form.Check
                                aria-label="option 1"
                                name="pdv"
                                className="dynamicForm_checkBox"
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm>
                        <Form.Group>
                            <Nav.Item>
                                <Nav.Link
                                    style={{ float: "right", color: "#849AA3" }}
                                    onClick={handleMenageColumns}
                                >
                                    {t('smartInvoicing.manageTheColumns')}
                                </Nav.Link>
                            </Nav.Item>
                        </Form.Group>
                    </Col>
                    <DynamicFormField
                        inputFields={tableRows}
                        handleChangeInput={handleChangeInput}
                        handleAddRow={handleAddRow}
                        handleRemoveRow={handleRemoveFields}
                        isHiddenRabat={!isHiddenRabat}
                        isHiddenServiceDescription={!isHiddenServiceDescription}
                    />
                    <Col sm>
                        <Form.Group>
                            <Nav.Item>
                                <Nav.Link
                                    style={{ float: "right", color: "#849AA3" }}
                                    onClick={handleMenageData}
                                >
                                    {t('smartInvoicing.manageTableData')}
                                </Nav.Link>
                            </Nav.Item>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm >
                        <InvoiceNote
                            handleChange={onDescriptionChange}
                            invoice={newInvoice}
                        />
                    </Col>
                    <Col sm>
                        <InvoiceComment
                            handleChange={onDescriptionChange}
                            invoice={newInvoice}
                        />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm>
                        <Modal.Footer className="allInvoices_dashboard-footer">
                            <Grid item xs={12}>
                                <button
                                    className="addInvoice_clearButton"
                                    style={{ float: "left" }}
                                >
                                    {t('buttons.clear')}
                                </button>
                                <button
                                    className="addInvoice_saveButton"
                                    style={{ float: "right" }}
                                >
                                    {t('buttons.save')}
                                </button>
                            </Grid>
                        </Modal.Footer>
                    </Col>
                </Row>
            </Paper>
            <MenageTheColumnsModal
                handleChange={handleCheckboxChange}
                show={menageColumnsModal}
                onHide={() => setMenageColumnsModal(false)}
                modalClose={handleMenageColumnsClose}
            />
            <ManageTableDataModal
                show={menageTableDataModal}
                onHide={() => setMenageTableDataModal(false)}
                modalClose={handleMenageDataClose}
            />
            <ManageDocumentTypeModal
                show={menageDocumentTypeModal}
                onHide={() => setManageDocumentTypeModal(false)}
                modalClose={handleMenageDocumentTypeClose}
            />
        </Container>
    );
};