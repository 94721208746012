import React from 'react';
import { Grid } from '@material-ui/core';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean;
    onHide: () => void;
    modalClose: any;
    handleChange: any;
    invoice: any;
};

export default function AddInvoiceNoteModal({
    show,
    onHide,
    modalClose,
    handleChange,
    invoice
}: Props) {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='lg'
                dialogClassName="allInvoicesModal_modal"
            >
                <Modal.Header
                    closeButton
                    className="allInvoices_dashboard-header"
                    style={{ background: "#849BA4" }}
                >
                    <Modal.Title className="modal_title">{t('invoiceNote.addNewNote')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t('invoiceNote.noteTitle')}</Form.Label>
                        <Form.Control
                            name="Note_modal"
                            style={{ resize: "none" }}
                            type='text'
                        />
                    </Form.Group>
                    <Form.Group className="mt-4">
                        <Form.Label>{t('invoiceNote.noteDescription')}</Form.Label>
                        <Form.Control
                            name="Note"
                            style={{ resize: "none" }}
                            as="textarea"
                            rows={4}
                            type='text'
                            onChange={(e) => handleChange(e, "Note")}
                            value={invoice.Note}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Grid item xs={12}>
                        <button
                            className="allInvoicesModal_dashboard-closeButton"
                            onClick={modalClose}
                            style={{float: "left"}}
                        >
                            {t('buttons.close')}
                        </button>
                        <button
                            className="allInvoicesModal_dashboard-saveButton"
                            onClick={modalClose}
                            style={{float: "right"}}
                        >
                            {t('buttons.save')}
                        </button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    );
};