import React from 'react';
import LogoBar from '../../../components/logoBar/logoBar';
import AboutUsContent from './aboutUsContent';
import Header from '../../navigations/header/header';
import Footer from '../../footer/footer';
import './aboutUsPage.css';

export default function AboutUsPage() {
    return (
        <>
            <Header />
            <LogoBar />
            <AboutUsContent />
            <Footer />
        </>
    );
};