/* eslint-disable import/no-anonymous-default-export */
import axios, { AxiosRequestConfig } from "axios";

const api = (method: string, url: string, variables?: any) =>
    new Promise((resolve, reject) => {
        axios({
            baseURL: process.env.REACT_APP_API_URL,
            url: url,
            method,
            params: method === "get" ? variables : undefined,
            data: method !== "get" ? variables : undefined,
        } as AxiosRequestConfig).then(
            (response) => {
                resolve(response.data);
            },
            (error) => {
                if (error.response) {
                    reject(error.response.data.error);
                } else {
                    reject({
                        code: "INTERNAL_ERROR",
                        status: 503,
                        data: {},
                    });
                }
            }
        );
    });

export default {
    get: (url: string, variables?: any) => api("get", url, variables),
    post: (url: string, variables?: any) => api("post", url, variables),
    put: (url: string, variables?: any) => api("put", url, variables),
    patch: (url: string, variables?: any) => api("patch", url, variables),
    delete: (url: string, variables?: any) => api("delete", url, variables),
};