import React from 'react';
import {
    Grid,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

interface Props {
    handleJavaScriptModalOpen: any;
    handleTypeScriptModalOpen: any;
    handleReactModalOpen: any;
    handleWebServiceModalOpen: any;
    handleWebApplicationModalOpen: any;
    handleWebDeisgnModalOpen: any;
    handleCustomSoftwareModalOpen: any;
    handleAngularModalOpen: any;
    handleDesignModalOpen: any;
    handleCSharpModalOpen: any;
    handleDotNetModalOpen: any;
    handleDatabaseModalOpen: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        about_container: {
            width: "100%",
            alignContent: "center",
            alignItems: "center",
            padding: "20px"
        },
        technologyExpirience_grid: {
            width: "100%",
            justifyContent: "center",
            alignContent: "center",
            [theme.breakpoints.up('xs')]: {
                marginLeft: "25px",
            },
        },
        contentSection_text: {
            color: "white",
            fontWeight: 600,
            marginTop: "20px",
            cursor: "pointer",
            width: "100%"
        },
        column_grid: {
            marginTop: "50px"
        },
        title: {
            color: "white",
            fontWeight: 900,
            marginLeft: "145px",
            [theme.breakpoints.down('xs')]: {
                color: "white",
                fontWeight: 900,
                marginLeft: 0
            },
        }
    }),
);

export default function TechnologyExperience({
    handleAngularModalOpen,
    handleCSharpModalOpen,
    handleCustomSoftwareModalOpen,
    handleDatabaseModalOpen,
    handleDesignModalOpen,
    handleDotNetModalOpen,
    handleJavaScriptModalOpen,
    handleReactModalOpen,
    handleTypeScriptModalOpen,
    handleWebApplicationModalOpen,
    handleWebDeisgnModalOpen,
    handleWebServiceModalOpen
}: Props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid container spacing={0}>
            <Grid
                container
                className={classes.technologyExpirience_grid}
            >
                <Grid
                    item
                    xs={12}
                    sm={12}
                >
                    <Typography
                        variant="h3"
                        className={classes.title}
                    >
                        {t('homePage.technologyExpirience')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    style={{
                        marginTop: "50px",
                        width: "100%"
                    }}
                >
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleJavaScriptModalOpen}
                    >
                        {t('homePage.javaScript')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleTypeScriptModalOpen}
                    >
                        {t('homePage.typeScript')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleReactModalOpen}
                    >
                        {t('homePage.react')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    className={classes.column_grid}
                >
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleWebServiceModalOpen}
                    >
                        {t('homePage.webServices')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleWebApplicationModalOpen}
                    >
                        {t('homePage.webApplication')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleWebDeisgnModalOpen}
                    >
                        {t('homePage.webDesign')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    className={classes.column_grid}
                >
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleCustomSoftwareModalOpen}
                    >
                        {t('homePage.customSoftware')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleAngularModalOpen}
                    >
                        {t('homePage.angular')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleDesignModalOpen}
                    >
                        {t('homePage.design')}
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={1}
                    className={classes.column_grid}
                >
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleCSharpModalOpen}
                    >
                        {t('homePage.cSharp')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleDotNetModalOpen}
                    >
                        {t('homePage.dotNet')}
                    </Typography>
                    <Typography
                        variant="h4"
                        className={classes.contentSection_text}
                        onClick={handleDatabaseModalOpen}
                    >
                        {t('homePage.database')}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};