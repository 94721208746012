import React from 'react';
import { Container } from '@material-ui/core';
import Logo1 from '../../images/certificate.png';
import Logo2 from '../../images/microsoftPartner.png';
import './logoBar.css';

export default function LogoBar() {
    return (
        <Container className="logoBar_main">
            <div className="logoBar_title">
            </div>
            <div className="logoBar_img">
                <img
                    src={Logo1}
                    alt=""
                    className=""
                />
                <img
                    src={Logo2}
                    alt=""
                    className=""
                />
            </div>
        </Container>
    );
};