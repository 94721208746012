import React, { useState } from 'react';
import {
    Form,
    Row,
    Nav
} from 'react-bootstrap';
import AddInvoiceNoteModal from '../../features/modals/addInvoiceModals/addInvoiceNoteModal';
import InvoiceModel from '../../models/invoiceModel';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../customSelect/customSelect';

interface Props {
    handleChange: any;
    invoice: InvoiceModel;
};

export default function InvoiceNote({
    handleChange,
    invoice,
}: Props) {
    const { t } = useTranslation();
    const [addInvoiceNoteModal, setAddInvoiceNoteModal] = useState(false);

    const handleAddInvoiceNoteModal = () => {
        setAddInvoiceNoteModal(true);
    };

    const handleAddInvoiceNoteModalClose = () => {
        setAddInvoiceNoteModal(false);
    };

    return (
        <Row >
            <Form.Group>
                <Form.Label>
                    {t('invoiceNote.note')}
                </Form.Label>
                <CustomSelect
                    options={null}
                    placeholder={t('placeholder.chooseNote')}
                />
                <Form.Control
                    className="mt-4"
                    name="Note"
                    style={{ resize: "none" }}
                    as="textarea"
                    rows={4}
                    type='text'
                    onChange={(e) => handleChange(e, "Note")}
                    value={invoice.Note}
                />
                <Nav.Item>
                    <Nav.Link
                        style={{ color: "#849AA3" }}
                        onClick={handleAddInvoiceNoteModal}
                    >
                        {t('invoiceNote.addNewNote')}
                    </Nav.Link>
                </Nav.Item>
            </Form.Group>
            <AddInvoiceNoteModal
                show={addInvoiceNoteModal}
                onHide={() => setAddInvoiceNoteModal(false)}
                modalClose={handleAddInvoiceNoteModalClose}
                handleChange={handleChange}
                invoice={invoice}
            />
        </Row>
    );
};