import React, { useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable
} from 'react-beautiful-dnd';
import header from '../../images/invoiceHeader.png';
import headerContent from '../../images/invoiceContentHeader.png';
import invoiceTable from '../../images/invoiceTable.png';
import invoiceFooter from '../../images/invoiceFooter.png';
import uuid from "uuid/dist/v4";
import './templateComp.css'
import { Grid, Container } from '@material-ui/core';

const invoiceItems = [
  { id: uuid(), content: header },
  { id: uuid(), content: headerContent },
  { id: uuid(), content: invoiceTable },
  { id: uuid(), content: invoiceFooter },
];

const columnsFromBackend = {
  [uuid()]: {
    name: "Invoice Template",
    items: [],
    width: "600px",
    height: "800px",
    float: "left"
  },
  [uuid()]: {
    name: "Components",
    items: invoiceItems,
    width: "200px",
    height: "700px",
    float: "right",
  }
};

const onDragEnd = (result, columns, setColumns) => {
  console.log("onDragEnd")
  console.log(columns);
  console.log(result);
  if (!result.destination) return;
  const { source, destination } = result;


  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
};

export default function TemplateComp() {
  const [columns, setColumns] = useState(columnsFromBackend);

  return (
    <Container maxWidth="sm">
      <Grid item xs={12} sm={12} style={{ display: "flex" }}>
        <DragDropContext
          onDragEnd={result => onDragEnd(result, columns, setColumns)}
        >
          {Object.entries(columns).map(([columnId, column], index) => {
            return (
              <div
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                }}
                key={columnId}
              >
                <h2 key={columnId}>{column.name}</h2>
                <div style={{ margin: 8 }}>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided, snapshot) => {
                      return (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: snapshot.isDraggingOver
                              ? "lightblue"
                              : "lightgrey",
                            padding: 4,
                            width: column.width,
                            minHeight: column.height
                          }}
                        >
                          {column.items.map((item, index) => {
                            return (
                              <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={item.id}
                              >
                                {(provided, snapshot) => {
                                  return (
                                    <div
                                      key={item.id}
                                      className="characters-thumb"
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={{
                                        userSelect: "none",
                                        padding: 16,
                                        margin: "0 0 8px 0",
                                        minHeight: "50px",
                                        backgroundColor: snapshot.isDragging
                                          ? "#849AA3"
                                          : "#849AA3",
                                        color: "white",
                                        ...provided.draggableProps.style
                                      }}
                                    >
                                      <img
                                        key={item.id}
                                        src={item.content}
                                        alt=""
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </div>
              </div>
            );
          })}
        </DragDropContext>
      </Grid>
    </Container>
  );
};