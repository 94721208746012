import React from 'react';
import { 
    DialogActions, 
    Grid, 
    Typography, 
    Button,
    Dialog, 
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import angular from '../../../images/angular.png';
import { useTranslation } from 'react-i18next';
import './angularModal.css';

interface Props {
    open: any;
    modalClose: any;
};

export default function AngularModal({
    open,
    modalClose
}: Props) {
    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                style={{ borderRadius: 2 }}
                open={open}
                onClose={modalClose}
                aria-labelledby="angularModal_dialog-title"
                aria-describedby="angularModal_dialog-description"
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="angularModal_modal_title"
                    >
                        <Grid
                            item
                            xs={1}
                            sm={1}
                        >
                            <img
                                src={angular}
                                alt=""
                                className="angularModal_modal_logo_img"
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <DialogTitle
                                id="angularModal_dialog-title"
                                className="angularModal_dialog_title"
                            >
                                <b>{t('homePage.angularTitle')}</b>
                            </DialogTitle>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid
                            item
                            sm={12}
                        >
                            <DialogContentText
                                id="angularModal_dialog-description"
                                className="angularModal_dialog_content"
                            >
                                {t('homePage.angularGeneral')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="angularModal_modal_typography"
                            >
                                {t('homePage.angularUsedForTitle')}
                            </Typography>
                            <DialogContentText
                                id="angularModal_dialog-description"
                                className="angularModal_dialog_content"
                            >
                                {t('homePage.angularUsedForText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="angularModal_modal_typography"
                            >
                                {t('homePage.angularFrontOrBackTitle')}
                            </Typography>
                            <DialogContentText
                                id="angularModal_dialog-description"
                                className="angularModal_dialog_content"
                            >
                                {t('homePage.angularFrontOrBackText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="angularModal_modal_typography"
                            >
                                {t('homePage.angularEasyToLearnTitle')}
                            </Typography>
                            <DialogContentText
                                id="angularModal_dialog-description"
                                className="angularModal_dialog_content"
                            >
                                {t('homePage.angularEasyToLearnText')}
                            </DialogContentText>
                            <DialogActions className="angular_modal_dialogActions">
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        className="angular_modal_button"
                                        onClick={modalClose}
                                    >
                                        {t('buttons.close')}
                                    </Button>
                                </Grid>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </Dialog>
        </div >
    );
}
