import { Grid } from '@material-ui/core';
import React from 'react';
import { Modal } from 'react-bootstrap';
import ModalTable from '../../../../components/table/modalTable';
import './weekModal.css';

interface Props {
    show: boolean;
    onHide: () => void;
    modalClose: any;
}

export default function WeekModal({
    show,
    onHide,
    modalClose
}: Props) {
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='lg'
            >
                <Modal.Header style={{ background: "#849BA4" }} closeButton className="weekInvoices_dashboard-header">
                    <Modal.Title className="modal_title">Week Invoices</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ borderRadius: '40px' }}>
                    <ModalTable />
                </Modal.Body>
                <Modal.Footer>
                    <Grid item xs={12}>
                        <button
                            className="weekModal_dashboard-closeButton"
                            onClick={modalClose}
                            style={{ float: "left" }}
                        >
                            Close
                        </button>
                        <button
                            className="weekModal_dashboard-saveButton"
                            onClick={modalClose}
                            style={{ float: "right" }}
                        >
                            Save
                        </button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    );
};