import React, { useState } from 'react';
import { Form, Nav, Row } from 'react-bootstrap';
import InvoiceModel from '../../models/invoiceModel';
import AddInvoiceCommentModal from '../../features/modals/addInvoiceModals/addInvoiceCommentModal'
import { useTranslation } from 'react-i18next';
import CustomSelect from '../customSelect/customSelect';

interface Props {
    handleChange: any;
    invoice: InvoiceModel;
}

export default function InvoiceComment({
    handleChange,
    invoice
}: Props) {
    const { t } = useTranslation();
    const [addInvoiceCommentModal, setAddInvoiceCommentModal] = useState(false);
    const handleAddInvoiceCommentModal = () => {
        setAddInvoiceCommentModal(true);
    };
    const handleAddInvoiceCommentModalClose = () => {
        setAddInvoiceCommentModal(false);
    };

    return (
        <Row>
            <Form.Group>
                <Form.Label>
                    {t('invoiceComment.comment')}
                </Form.Label>
                <CustomSelect
                    options={null}
                    placeholder={t('placeholder.chooseComment')}
                />
                <Form.Control
                    className="mt-4"
                    name="Comment"
                    style={{ resize: "none" }}
                    as="textarea"
                    rows={4}
                    type='text'
                    onChange={(e) => handleChange(e, "Comment")}
                    value={invoice.Comment}
                />
                <Nav.Item>
                    <Nav.Link
                        style={{ color: "#849AA3" }}
                        onClick={handleAddInvoiceCommentModal}>
                        {t('invoiceComment.addNewComment')}
                    </Nav.Link>
                </Nav.Item> 
            </Form.Group>
            <AddInvoiceCommentModal
                show={addInvoiceCommentModal}
                onHide={() => setAddInvoiceCommentModal(false)}
                modalClose={handleAddInvoiceCommentModalClose}
            />
        </Row>
    );
};