/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import Image from '../../../images/secondLogo.png';
import { useTranslation } from 'react-i18next';
import {
    contactPage as contactPageRoute,
}
    from '../../../app/routes';
import { useHistory } from 'react-router-dom';
import './aboutUsContent.css';

export default function AboutUsContent() {
    const { t } = useTranslation();
    const history = useHistory();

    const handleContactPage = () => {
        history.push(contactPageRoute());
    };
    return (
        <section className="about-area">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-img">
                            <img src={Image} alt="" className="img-fluid" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 about-title">
                        <h2 className="text-uppercase pt-5">
                            <span>{t('aboutUsPage.title1')}</span>
                            <span>{t('aboutUsPage.title2')}</span>
                            <span>{t('aboutUsPage.title3')}</span>
                        </h2>
                        <div className="paragraph py-4 w-75">
                            <p className="para">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel adipisci corrupti non eius, corporis consequatur temporibus assumenda quos nisi cum!
                            </p>
                            <p className="para">
                                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Sequi, facilis ipsum? Doloribus numquam voluptatem necessitatibus dolore quo corporis,
                                dignissimos ducimus! Eos accusantium, atque laudantium eum accusamus minus quasi iusto dolor dolorem reiciendis eius magnam,
                                culpa voluptatum aut hic cum ea nesciunt id similique quo at error?
                                Fugiat consectetur optio itaque illum sint minima hic quis tenetur consequatur autem atque officiis sit ratione explicabo,
                                quo nulla laboriosam culpa, eveniet corrupti cum?
                            </p>
                        </div>
                        <button
                            onClick={handleContactPage}
                            type="button"
                            className="btn button primary-button text-uppercase about-button">
                            {t('buttons.contactUs')}
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
};