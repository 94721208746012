export const homePage = (): string => "/";
export const smartInvoicing = (): string => "/smart-invoicing";
export const loginPage = (): string => "/login-page";
export const learningPage = (): string => "/learning-page";
export const aboutUsPage = (): string => "/about-us";
export const contactPage = (): string => "/contact-us";
export const dashboard = (): string => "/dashboard";
export const profile = (): string => "/dashboard-profile";
export const addInvoice = (): string => "/dashboard-addInvoice";
export const passwordChange = (): string => "/dashboard-changePassword";
export const addClient = (): string => "/dashboard-addClient";
export const template = (): string => "/dashboard-template";