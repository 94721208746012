/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
    homePage as homePageRoute
}
    from '../../../src/app/routes';
import { useTranslation } from 'react-i18next';
import Logo from '../../images/Logo.png';
import rsIcon from '../../images/serbia.png';
import ukIcon from '../../images/uk.png';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import './learningPage.css';
import { Fade } from 'react-awesome-reveal';

export const HeaderLearning = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
  background-color: transparent;
  position: relative;
  z-index: 500;
  
  @media only Screen and (max-width: 64em) {
    padding: 0.5rem 3rem;
    background: #1a1d1e;
  }
  @media only Screen and (max-width: 40em) {
    padding: 0.5rem 1.5rem;
    background: #1a1d1e;
  }
`;

export const LogoSectionLearning = styled.a`
  display: flex;
  align-items: center;
  width: 2rem;
  height: auto;
  cursor: pointer;
  img {
    margin-right: 0.5rem;
    width: 200px;
  }

  @media only Screen and (max-width:1334px){
    display: inline-block;
    display: none;
}
@media only Screen and (max-width:48em){
  display: inline-block;
}
`;

function LearningPage() {
    const { t, i18n } = useTranslation();
    const history = useHistory();
    const ref = useRef(null);

    const lngs1 = [
        { nativeName: 'en', className: 'language_switch_learning', icon: ukIcon },
        { nativeName: 'rs', className: 'language_switch_learning', icon: rsIcon }
    ];

    const handleBacktToHome = () => {
        history.push(homePageRoute());
    };
    return (
        <>
            <div className="learning_body">
                <HeaderLearning ref={ref}>
                    <LogoSectionLearning>
                        <Fade>
                            <img
                                src={Logo}
                                alt="CodeBucks"
                                onClick={handleBacktToHome}
                            />
                        </Fade>
                    </LogoSectionLearning>
                </HeaderLearning>
                <div className="learning_main">
                    <header>
                        <div className="learning_footer">
                            <h3>{t('learningPageUnderConstruction.underConstructionTitle')}</h3>
                        </div>
                    </header>
                    <section >
                        <div className="learning_title">
                            <h1>{t('learningPageUnderConstruction.underConstructionSubtitle')}</h1>
                        </div>
                    </section>
                    <footer className="learning_footer">
                        <div className="learning_social-links">
                            <a href="https://www.facebook.com/midenasit/"><i className="fab fa-facebook-f facebook_icon"></i></a>
                            <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fit_midenas%3Figshid%3DYmMyMTA2M2Y%253D%26fbclid%3DIwAR3upvNNHiVo2Kl4kc-eet6e1PWrwy45_wDuX8K0B-RIcNvKuUEUxKNzTXw&h=AT2J_rAGr7qhieRLFaTv08GTW2LJXZBxU2OuKhlUCSF5Ecwlq1gziW7gN3qgrTs8C9oGQzaP6iv6eHBzq65On051EdZtzIgfBCiepMWd9a-R0bYbmPmb2sgAstVg-rz-WLiYEA"><i className="fab fa-instagram insta_icon"></i></a>
                            <a href="https://www.linkedin.com/company/midenas-doo/about/"><i className="fab fa-linkedin-in linkedIn_icon"></i></a>
                        </div>
                        <div>
                            {lngs1.map((lng) => (
                                <Tooltip
                                    key={lng.nativeName}
                                    title={lng.nativeName === 'en' ? 'UK' : 'RS'}
                                    placement="bottom"
                                >
                                    <button
                                        key={lng.nativeName}
                                        className={lng.className}
                                        style={{ fontWeight: i18n.resolvedLanguage === lng.nativeName ? 'bold' : 'normal' }}
                                        type="submit"
                                        onClick={() => i18n.changeLanguage(lng.nativeName)}
                                    >
                                        <img
                                            key={lng.nativeName}
                                            src={lng.icon}
                                            alt={lng.nativeName}
                                        />
                                    </button>
                                </Tooltip>
                            ))}
                        </div>
                    </footer>
                    <div className="backToHome">
                        <h3 onClick={handleBacktToHome}>&#x2190; {t('learningPageUnderConstruction.backToHome')}</h3>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LearningPage;