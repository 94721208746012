import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';

interface Props {
    placeholder: string;
    options: any;
};

export default function CustomSelect({
    placeholder,
    options,
}: Props) {

    return (
        <Autocomplete
            id="combo-box-demo"
            options={options}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label={placeholder}
                    variant="outlined"
                    size="small"
                />
            }
        />
    );
};