import React from 'react';
import { Grid } from '@material-ui/core';
import { Form, InputGroup, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean;
    onHide: () => void;
    modalClose: any;
    handleChange: any;
};

export default function MenageTheColumnsModal({
    show,
    onHide,
    modalClose,
    handleChange
}: Props) {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='sm'
                dialogClassName="allInvoicesModal_modal"
            >
                <Modal.Header
                    closeButton
                    className="allInvoices_dashboard-header"
                    style={{ background: "#849BA4" }}
                >
                    <Modal.Title className="modal_title">
                        {t('columnsModal.manageColumns')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-3">
                        <Form.Check
                            disabled
                            checked={true}
                            onChange={(e) => handleChange(e)}
                            name="NameOfService"

                        />
                        <Form.Label
                            style={{ marginLeft: '5px' }}
                            placeholder="Unit Of Measure"
                        >
                            {t('columnsModal.nameOfService')}
                        </Form.Label>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Check
                            onChange={(e) => handleChange(e)}
                            name="ServiceDescription"
                        />
                        <Form.Label
                            style={{ marginLeft: '5px' }}
                            placeholder="Unit Of Measure"
                        >
                            {t('columnsModal.serviceDescription')}
                        </Form.Label>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Check
                            disabled
                            checked={true}
                            name="UnitOfMeasure"

                        />
                        <Form.Label
                            style={{ marginLeft: '5px' }}
                            placeholder="Unit Of Measure"
                        >
                            {t('columnsModal.unitOfMeasure')}
                        </Form.Label>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Check
                            disabled
                            checked={true}
                            onChange={(e) => handleChange(e)}
                            name="Quantity"

                        />
                        <Form.Label
                            style={{ marginLeft: '5px' }}
                        >
                            {t('columnsModal.quanity')}
                        </Form.Label>
                    </InputGroup>
                    <InputGroup className="mb-3">
                        <Form.Check
                            name="Rabat"
                            onClick={(e) => handleChange(e)}
                        />
                        <Form.Label
                            style={{ marginLeft: '5px' }}
                            placeholder="Unit Of Measure"
                        >
                            {t('columnsModal.rabat')}
                        </Form.Label>
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Grid item xs={12}>
                        <button
                            className="allInvoicesModal_dashboard-closeButton"
                            onClick={modalClose}
                            style={{ float: "left" }}
                        >
                            {t('buttons.close')}
                        </button>
                        <button
                            className="allInvoicesModal_dashboard-saveButton"
                            onClick={modalClose}
                            style={{ float: "right" }}
                        >
                            {t('buttons.save')}
                        </button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    );
};