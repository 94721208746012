import React from 'react';
import { Grid } from '@material-ui/core';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface Props {
    show: boolean;
    onHide: () => void;
    modalClose: any;
};

export default function AddInvoiceCommentModal({
    show,
    onHide,
    modalClose
}: Props) {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='lg'
                dialogClassName="allInvoicesModal_modal"
            >
                <Modal.Header
                    closeButton
                    className="allInvoices_dashboard-header"
                    style={{ background: "#849BA4" }}
                >
                    <Modal.Title
                        className="modal_title"
                    >
                        {t('invoiceComment.addNewComment')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>{t('invoiceComment.commentTitle')}</Form.Label>
                        <Form.Control
                            name="Note"
                            style={{ resize: "none" }}
                            type="text"
                        />
                    </Form.Group>
                    <Form.Group className="mt-4"
                    >
                        <Form.Label>{t('invoiceComment.commentDescription')}</Form.Label>
                        <Form.Control
                            name="Note"
                            style={{ resize: "none" }}
                            as="textarea"
                            rows={4}
                            type="text"
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Grid item xs={12}>
                        <button
                            className="allInvoicesModal_dashboard-closeButton"
                            onClick={modalClose}
                            style={{float: "left"}}
                        >
                            {t('buttons.close')}
                        </button>
                        <button
                            className="allInvoicesModal_dashboard-saveButton"
                            onClick={modalClose}
                            style={{float: "right"}}
                        >
                            {t('buttons.save')}
                        </button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    );
};