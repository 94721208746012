import React from 'react';
import Footer from '../../footer/footer';
import ScrollToTop from '../../../components/scrollToTop/scrollToTop';
import Header from '../../navigations/header/header';
import Slider from '../../../components/slider/slider';
import LogoBar from '../../../components/logoBar/logoBar';
import ContentSection from '../../contentSection/contentSection';
import CookieConsent from '../../../components/cookieConsent/cookieConsent';

function HomePage() {
    return (
        <>
            <ScrollToTop />
            <Header />
            <Slider />
            <LogoBar />
            <ContentSection />
            <CookieConsent />
            <Footer />
        </>
    );
};

export default HomePage;