import CookieConsent from 'react-cookie-consent';

export default function CookieConsentComp() {
    return (
        <CookieConsent
            className="cookie_contsent"
            location="bottom"
            buttonText="I understand"
            cookieName="Midenas"
            style={{
                background: "#1A1D1E",
                textAlign: "center"
            }}
            buttonStyle={{
                fontSize: "16px",
                background: "#849AA3",
                color: "white",
                borderRadius: "20px",
                padding: "10px"
            }}
            expires={150}
        >
            This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
    );
};