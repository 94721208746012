import { Paper } from '@material-ui/core';
import React, { useState } from 'react';
import {
    Col,
    Container,
    Form,
    Modal,
    Row
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CustomSelect from '../../../components/customSelect/customSelect';
import ClientModel from '../../../models/clientModel';

const languageOptions = [
    { value: 'serbian', label: 'Serbian' },
    { value: 'english', label: 'English' },
    { value: 'german', label: 'German' }
];

const currencyOptions = [
    { value: 'dinar', label: 'RSD' },
    { value: 'euro', label: 'EU' },
    { value: 'dollar', label: 'USD' }
];

const cityOptions = [
    { value: 'beograd', label: 'Beograd' },
    { value: 'noviSad', label: 'Novi Sad' },
    { value: 'odzaci', label: 'Odzaci' }
];

export default function AddClient() {
    const { t } = useTranslation();

    const INIT_NEW_CLIENT: ClientModel = {
        CompanyName: "",
        TaxId: "",
        IdNumber: "",
        ServiceId: "",
        DocumentLanguage: "",
        Currency: "",
        City: "",
        Street: "",
        Number: "",
        PostalCode: ""
    };

    const [newClient, setNewClient] = useState<ClientModel>(INIT_NEW_CLIENT);

    const handleChangeClient = (e: any) => {
        const value = e.target.value;
        const name = e.target.name;
        setNewClient({ ...newClient, [name]: value })
    };

    return (
        <Container
            fluid="sm"
            className="addInvoice_container"
        >
            <Paper style={{padding: "20px"}} elevation={3}>
                <div className="addInvoice_title">
                    {t('addClient.addClientTitle')}
                </div>
                <Row>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.companyName')}</Form.Label>
                            <Form.Control
                                name="CompanyName"
                                onChange={(e) => handleChangeClient(e)}
                                value={newClient.CompanyName}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.pib')}</Form.Label>
                            <Form.Control
                                name="TaxId"
                                onChange={(e) => handleChangeClient(e)}
                                value={newClient.TaxId}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.id')}</Form.Label>
                            <Form.Control
                                name="IdNumber"
                                onChange={(e) => handleChangeClient(e)}
                                value={newClient.IdNumber}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.serviceId')}</Form.Label>
                            <Form.Control
                                name="ServiceId"
                                onChange={(e) => handleChangeClient(e)}
                                value={newClient.ServiceId}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.documentLanguage')}</Form.Label>
                            <CustomSelect
                                options={languageOptions}
                                placeholder={t('placeholder.chooseLanguage')}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.currency')}</Form.Label>
                            <CustomSelect
                                options={currencyOptions}
                                placeholder={t('placeholder.chooseCurrency')}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.city')}</Form.Label>
                            <CustomSelect
                                options={cityOptions}
                                placeholder={t('placeholder.chooseCity')}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.street')}</Form.Label>
                            <Form.Control
                                name="Street"
                                onChange={(e) => handleChangeClient(e)}
                                value={newClient.Street}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.number')}</Form.Label>
                            <Form.Control
                                name="Number"
                                onChange={(e) => handleChangeClient(e)}
                                value={newClient.Number}
                            />
                        </Form.Group>
                    </Col>
                    <Col sm className="addInvoice_inputGroup mt-4">
                        <Form.Group>
                            <Form.Label>{t('addClient.postalCode')}</Form.Label>
                            <Form.Control
                                name="PostalCode"
                                onChange={(e) => handleChangeClient(e)}
                                value={newClient.PostalCode}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col sm>
                        <Modal.Footer className="allInvoices_dashboard-footer">
                            <button
                                className="addInvoice_clearButton"
                            >
                                {t('buttons.clear')}
                            </button>
                            <button
                                className="addInvoice_saveButton"
                            >
                                {t('buttons.save')}
                            </button>
                        </Modal.Footer>
                    </Col>
                </Row>
            </Paper>
        </Container>
    );
};