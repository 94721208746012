/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../features/dashboard/dashboard.css';
import '../../components/profileCard/profileCard.css';
import { createStyles, makeStyles, Paper, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            borderRadius: 20,
        },
    }),
);

export default function CompanyProfile() {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <>
            <div className="dashboard_details-profile">
                <Paper style={{padding: "20px"}} elevation={3} className={classes.paper}>
                    <div >
                        <div className="container">
                            <div className="row d-flex justify-content-center">
                                <div className="col-md-10 mt-5 pt-5">
                                    <div className="row z-depth-3">
                                        <div className="col-sm-4  rounded-left profileCard_profileImg">
                                            <div className="card-block text-center text-white">
                                                <i className="far fa-building fa-7x mt-5"></i>
                                                <h2 className="font-weight-bold mt-4">Company Name</h2>
                                                <i className="far fa-edit fa-2x mb-4"></i>
                                            </div>
                                        </div>
                                        <div className="col-sm-8 bg-white rounded-right">
                                            <h3 className="mt-3">{t('profilePage.information')}</h3>
                                            <hr className="profileCard_hr" />
                                            <div className="row profileCard_label">
                                                <div className="col-sm-6 profileCard_label">
                                                    <p className="font-weight-bold">{t('profilePage.firstName')}</p>
                                                    <h6 className="text-muted">Srdjan</h6>
                                                </div>
                                                <div className="col-sm-6 profileCard_label">
                                                    <p className="font-weight-bold">{t('profilePage.email')}</p>
                                                    <h6 className="text-muted">srdjan.pajic.ns@gmail.com</h6>
                                                </div>
                                                <div className="col-sm-6 profileCard_label">
                                                    <p className="font-weight-bold">{t('profilePage.lastName')}</p>
                                                    <h6 className="text-muted">Pajic</h6>
                                                </div>
                                                <div className="col-sm-6 profileCard_label">
                                                    <p className="font-weight-bold">{t('profilePage.phone')}</p>
                                                    <h6 className="text-muted">069/212-85-00</h6>
                                                </div>
                                            </div>
                                            <h4 className="mt-3">{t('profile.invoicesProfile')}</h4>
                                            <hr className="profileCard_hr" />
                                            <div className="row">
                                                <div className="col-sm-6 profileCard_label">
                                                    <p className="font-weight-bold">{t('profile.recentProfile')}</p>
                                                </div>
                                            </div>
                                            <ul className="list-umstyled d-flex justify-content-center mt-4">
                                                <li className="profileCard_icon"><a href="#"><i className="fab fa-facebook-f px-3 h4 profileCard_icon"></i></a></li>
                                                <li className="profileCard_icon"><a href="#"><i className="fab fa-linkedin px-3 h4 profileCard_icon"></i></a></li>
                                                <li className="profileCard_icon"><a href="#"><i className="fab fa-twitter px-3 h4 profileCard_icon"></i></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Paper>
            </div>
        </>
    );
};