/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import invoice from '../../../images/invoice.png';
import { useTranslation } from 'react-i18next';
import '../dashboard.css';

export default function MainDashboardDetails() {
    const { t } = useTranslation();

    return (
        <div className="dashboard_details">
            <div className="dashboard_recentOrders">
                <div className="dashboard_cardHeader">
                    <h2> {t('mainDashboard.listOfInvoices')} </h2>
                </div>
                <table>
                    <thead >
                        <tr>
                            <td>Lorem1</td>
                            <td>Lorem2</td>
                            <td>Lorem3</td>
                            <td>Lorem4</td>
                        </tr>
                    </thead>
                    <tr>
                        <td>Body1</td>
                        <td>Body2</td>
                        <td>Body3</td>
                        <td><span className="status delivered">Body3</span></td>
                    </tr>
                    <tr>
                        <td>Body1</td>
                        <td>Body2</td>
                        <td>Body3</td>
                        <td><span className="status delivered">Body3</span></td>
                    </tr>
                    <tr>
                        <td>Body1</td>
                        <td>Body2</td>
                        <td>Body3</td>
                        <td><span className="status delivered">Body3</span></td>
                    </tr>
                    <tr>
                        <td>Body1</td>
                        <td>Body2</td>
                        <td>Body3</td>
                        <td><span className="status delivered">Body3</span></td>
                    </tr>
                </table>
            </div>
            <div className="recentInvoicing">
                <div className="dashboard_cardHeader">
                    <h2>{t('mainDashboard.recentInvoice')}</h2>
                </div>
                <table className="newInvoicing_table">
                    <tr className="newInvoicing_tr">
                        <td
                            className="newInvoicing_td"
                            width="60px"
                        >
                            <div className="newInvoicing_imgBx"><img src={invoice} /></div>
                        </td>
                        <td className="newInvoicing_td"><h4>First<br /><span>Invoice</span></h4></td>
                    </tr>
                    <tr className="newInvoicing_tr">
                        <td
                            className="newInvoicing_td"
                            width="60px"><div className="newInvoicing_imgBx"><img src={invoice} /></div>
                        </td>
                        <td className="newInvoicing_td"><h4>First<br /><span>Invoice</span></h4></td>
                    </tr>
                    <tr className="newInvoicing_tr">
                        <td
                            className="newInvoicing_td"
                            width="60px"><div className="newInvoicing_imgBx"><img src={invoice} /></div>
                        </td>
                        <td className="newInvoicing_td"><h4>First<br /><span>Invoice</span></h4></td>
                    </tr>
                    <tr className="newInvoicing_tr">
                        <td
                            className="newInvoicing_td"
                            width="60px"><div className="newInvoicing_imgBx"><img src={invoice} /></div>
                        </td>
                        <td className="newInvoicing_td"><h4>First<br /><span>Invoice</span></h4></td>
                    </tr>
                    <tr className="newInvoicing_tr">
                        <td
                            className="newInvoicing_td"
                            width="60px"><div className="newInvoicing_imgBx"><img src={invoice} /></div>
                        </td>
                        <td className="newInvoicing_td"><h4>First<br /><span>Invoice</span></h4></td>
                    </tr>
                </table>
            </div>
        </div>
    );
};