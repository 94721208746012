import styled from 'styled-components';
import { motion } from 'framer-motion';

export const BoxContainer = styled.div`
width: 400px;
min-height: 555px;
display: flex;
flex-direction: column;
border-radius 19px;
background-color: #fff;
box-shadow: 0 0 15px rgba(15, 15, 15, 0.28);
position: relative;
overflow: hidden;
margin: auto;
margin-top: 1rem;
justify-content: center;
`;

export const TopContainer = styled.div`
width: 100%;
height: 250px;
display: flex;
flex-direction: column;
justify-content: flex-end;
padding: 0 1.8em;
padding-bottom: 5em;
`;

export const BackDrop = styled(motion.div)`
width: 160%;
height: 450px;
position: absolute;
display: flex;
flex-direction: flex;
border-radius: 50%;
transform: rotate(60deg);
top: -270px;
left: -70px;
background: rgb(132,155,164);
background: linear-gradient(
    90deg, rgba(132,155,164,1) 20%, 
    rgba(51,74,82,1) 100%
    );
`;

export const HeaderContainer = styled.h2`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const HeaderText = styled.h2`
font-size: 30px;
line-weight: 600;
line-height: 1.24;
color: #fff;
z-index: 10;
margin: 0;
`;

export const SmallText = styled.h5`
color: #fff;
font-weight: 500;
font-size: 11px;
z-index: 10;
margin: 0;
margin-top 7px; 
`;

export const InnerContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;