import {
    configureStore,
    ThunkAction,
    Action
  } from '@reduxjs/toolkit';
import emailSlice from '../slices/emailSlice';
import snackbarReducer from '../slices/snackbarSlice'
  
  export const store = configureStore({
    reducer: {
      email: emailSlice,
      snackbar: snackbarReducer
    },
  });
  
  export type AppDispatch = typeof store.dispatch;
  export type RootState = ReturnType<typeof store.getState>;
  export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
  >;