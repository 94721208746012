/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import {
    profile as profileRoute,
    homePage as homePageRoute,
    dashboard as dashboardRoute,
    addInvoice as addInvoiceRoute,
    passwordChange as passwordChangeRoute,
    addClient as addClientRoute,
    template as templateRoute
}
    from "../../app/routes";
import rsIcon from "../../images/serbia.png";
import ukIcon from "../../images/uk.png";
import { useHistory } from "react-router-dom";
import {
    HamburgerBtnInvoice,
    MobileMenuInvoice
}
    from '../dashboard/dashboardStyle';
import { HiTemplate } from 'react-icons/hi';
import { Container, Tooltip } from "@material-ui/core";
import { useTranslation } from 'react-i18next';


export default function MobileMenu() {
    const history = useHistory();
    const { i18n, t } = useTranslation();

    const lngs1 = [
        { nativeName: 'en', className: 'language_switch_header', icon: ukIcon },
        { nativeName: 'rs', className: 'language_switch_header', icon: rsIcon }
    ];

    const [click, setClick] = useState(false);

    const handleProfilePage = () => {
        history.push(profileRoute());
    };

    const handleHomepage = () => {
        history.push(homePageRoute());
    };

    const handleDashboardPage = () => {
        history.push(dashboardRoute())
    };

    const handleAddInvoicePage = () => {
        history.push(addInvoiceRoute());
    };

    const handlePasswordPage = () => {
        history.push(passwordChangeRoute());
    };

    const handleAddClient = () => {
        history.push(addClientRoute())
    }

    const handleTemplatePage = () => {
        history.push(templateRoute())
    };

    return (
        <Container>
            <HamburgerBtnInvoice clicked={click} onClick={() => setClick(!click)}>
                <span></span>
            </HamburgerBtnInvoice>
            <MobileMenuInvoice clicked={click}>
                <a href="#" onClick={handleDashboardPage}>
                    <span className="dashboard_icon-mobile"><i className="fas fa-home dashboard_fas"></i></span>
                    <span className="dashboard_title-mobile">{t('dashboard.dashboardSide')}</span>
                </a>
                <a href="#" onClick={handleAddInvoicePage}>
                    <span className="dashboard_icon-mobile"><i className="fas fa-file-alt"></i></span>
                    <span className="dashboard_title">{t('dashboard.addInvoice')}</span>
                </a>
                <a href="#" onClick={handleAddClient}>
                    <span className="dashboard_icon-mobile"><i className="fas fa-users"></i></span>
                    <span className="dashboard_title">{t('dashboard.addClient')}</span>
                </a>
                <a href="#" onClick={handleTemplatePage}>
                    <span className="dashboard_icon-mobile"><HiTemplate style={{ fontSize: '40px', color: 'white' }} /></span>
                    <span className="dashboard_title">{t('dashboard.template')}</span>
                </a>
                <a href="#" onClick={handleProfilePage}>
                    <span className="dashboard_icon-mobile"><i className="fas fa-users"></i></span>
                    <span className="dashboard_title">{t('dashboard.profileSide')}</span>
                </a>
                <a href="#" onClick={handlePasswordPage}>
                    <span className="dashboard_icon-mobile"><i className="fas fa-lock"></i></span>
                    <span className="dashboard_title">{t('dashboard.passwordSide')}</span>
                </a>
                <a href="#" onClick={handleHomepage}>
                    <span className="dashboard_icon-mobile"><i className="fas fa-sign-out-alt"></i></span>
                    <span className="dashboard_title">{t('dashboard.signOutSide')}</span>
                </a>
                <div>
                    {lngs1.map((lng) => (
                        <Tooltip
                            key={lng.nativeName}
                            title={lng.nativeName === 'en' ? 'UK' : 'RS'}
                            placement="bottom"
                        >
                            <button
                                key={lng.nativeName}
                                className={lng.className}
                                style={{ fontWeight: i18n.resolvedLanguage === lng.nativeName ? 'bold' : 'normal' }}
                                type="submit"
                                onClick={() => i18n.changeLanguage(lng.nativeName)}
                            >
                                <img
                                    key={lng.nativeName}
                                    src={lng.icon}
                                    alt={lng.nativeName}
                                />
                            </button>
                        </Tooltip>
                    ))}
                </div>
            </MobileMenuInvoice>
        </Container>
    );
};