
import React from "react";
import {
  makeStyles,
  createStyles,
} from '@material-ui/core';
import { Button } from "@material-ui/core";
import Carousel, { consts } from "react-elastic-carousel";
import carousel1 from '../../images/carousel1.jpg';
import carousel2 from '../../images/carousel2.jpg';
import carousel3 from '../../images/carousel3.jpg';
import carousel4 from '../../images/carousel4.jpg';
import Item from "./item";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import "./styles.css";

const useStyles = makeStyles((theme) =>
  createStyles({
    arrow_button: {
      color: "#849AA3",
      fontSize: "70px",
      [theme.breakpoints.down('xs')]: {
        display: "none"
      },
    },
  }),
);

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 1 },
  { width: 768, itemsToShow: 1 },
  { width: 1200, itemsToShow: 3 },
];

export default function CustomCarousel(props) {
  const classes = useStyles();
  function myArrow({
    type,
    onClick,
    isEdge
  }) {
    const pointer = type === consts.PREV ?
      <ArrowBackIosIcon className={classes.arrow_button} />
      :
      <ArrowForwardIosIcon className={classes.arrow_button} />
    return (
      <Button
        className={classes.arrow_button}
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </Button>
    )
  }
  return (
    <>
      <div className="App">
        <Carousel
          renderArrow={myArrow}
          breakPoints={breakPoints}
          transitionMs={500}
          disableArrowsOnEnd={false}
          enableSwipe={true}
          enableAutoPlay={true}
          autoPlaySpeed={4000}
          pagination={false}
        >
          <Item>
            <img
              src={carousel1}
              alt=""
              className="carousel_item"
            />
          </Item>
          <Item>
            <img
              src={carousel2}
              alt=""
              className="carousel_item"
            />
          </Item>
          <Item>
            <img
              src={carousel3}
              alt=""
              className="carousel_item"
            />
          </Item>
          <Item>
            <img
              src={carousel4}
              alt=""
              className="carousel_item"
            />
          </Item>
        </Carousel>
      </div>
    </>
  );
};