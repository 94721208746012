import React from 'react';
import {
    DialogActions,
    Grid,
    Typography,
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import customSoftware from '../../../images/customSoftware.png';
import { useTranslation } from 'react-i18next';
import './customSoftwareModal.css';

interface Props {
    open: any;
    modalClose: any;
}

export default function CustomSoftwareModal({
    open,
    modalClose
}: Props) {
    const { t } = useTranslation();

    return (
        <div>
            <Dialog
                style={{ borderRadius: 2 }}
                open={open}
                onClose={modalClose}
                aria-labelledby="customSoftwareModal_dialog-title"
                aria-describedby="customSoftwareModal_dialog-description"
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="customSoftwareModal_modal_title"
                    >
                        <Grid
                            item
                            xs={1}
                            sm={1}
                        >
                            <img
                                src={customSoftware}
                                alt=""
                                className="customSoftwareModal_modal_logo_img"
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <DialogTitle
                                id="customSoftwareModal_dialog-title"
                                className="customSoftwareModal_dialog_title"
                            >
                                <b>{t('homePage.customSoftwareTitle')}</b>
                            </DialogTitle>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid
                            item
                            sm={12}
                        >
                            <DialogContentText
                                id="customSoftwareModal_dialog-description"
                                className="customSoftwareModal_dialog_content"
                            >
                                {t('homePage.customSoftwareGeneral')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="customSoftwareModal_modal_typography"
                            >
                                {t('homePage.customSoftwareImportanceTitle')}
                            </Typography>
                            <DialogContentText
                                id="customSoftwareModal_dialog-description"
                                className="customSoftwareModal_dialog_content"
                            >
                                {t('homePage.customSoftwareImportanceText')}

                            </DialogContentText>
                            <DialogActions className="customSoftware_modal_dialogActions">
                                <Button
                                    variant="outlined"
                                    className="customSoftware_modal_button"
                                    onClick={modalClose}
                                >
                                    {t('buttons.close')}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </Dialog>
        </div >
    );
}
