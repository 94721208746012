/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import { LoginForm } from './logInForm';
import { AccountContext } from './accountContext';
import { SignupForm } from './signupForm';
import {
    BoxContainer,
    TopContainer,
    BackDrop,
    HeaderContainer,
    HeaderText,
    SmallText,
    InnerContainer
} from './loginPageStyle';
import { useTranslation } from 'react-i18next';
import rsIcon from "../../images/serbia.png";
import ukIcon from "../../images/uk.png";
import { Form } from 'react-bootstrap';
import {
    homePage as homePageRoute
}
    from "../../app/routes";
import { Tooltip } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import './loginPage.css';

const backdropVariant = {
    expanded: {
        width: "233%",
        height: "1050px",
        borderRadius: "20%",
        transform: "rotate(60deg)"
    },
    collapsed: {
        width: "130%",
        height: "520px",
        borderRadius: "50%",
        transform: "rotate(140deg)"
    }
};

const expandedTransition = {
    type: "spring",
    duration: 2.3,
    stiffness: 30,
};

function LoginPage() {
    const lngs1: any = [
        { nativeName: 'en', className: 'language_switch_loginPage', icon: ukIcon },
        { nativeName: 'rs', className: 'language_switch_loginPage', icon: rsIcon }
    ];
    const { t, i18n } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
    const [active, setActive] = useState("signin");
    const history = useHistory();

    const playExpandingAnimation = () => {
        setIsExpanded(true)
        setTimeout(() => {
            setIsExpanded(false)
        }, expandedTransition.duration * 1000 - 1500);
    };

    const switchToSignup = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signup");
        }, 400);
    };

    const switchToSignin = () => {
        playExpandingAnimation();
        setTimeout(() => {
            setActive("signin");
        }, 400);
    };

    const handleHomePage = () => {
        history.push(homePageRoute());
    };

    const contextValue = { switchToSignup, switchToSignin };

    return (
        <AccountContext.Provider value={contextValue}>
            <BoxContainer>
                <TopContainer>
                    <BackDrop
                        initial={false}
                        animate={isExpanded ? "expanded" : "collapsed"}
                        variants={backdropVariant}
                        transition={expandedTransition}
                    />
                    {active === "signin" && <HeaderContainer>
                        <HeaderText>{t('loginPage.headerLogIn')}</HeaderText>
                        <SmallText>{t('loginPage.headerLoginSubtitle')}</SmallText>
                    </HeaderContainer>}
                    {active === "signup" && <HeaderContainer>
                        <HeaderText>{t('loginPage.headerSignUp')}</HeaderText>
                        <SmallText>{t('loginPage.headerSignUpSubtitle')}</SmallText>
                    </HeaderContainer>}
                </TopContainer>
                <InnerContainer>
                    {active === "signin" && <LoginForm />}
                    {active === "signup" && <SignupForm />}
                </InnerContainer>
                <div>
                    {lngs1.map((lng: any) => (
                        <Tooltip
                            key={lng.nativeName}
                            title={lng.nativeName === 'en' ? 'UK' : 'RS'}
                            placement="bottom"
                        >
                            <button
                                key={lng.nativeName}
                                className={lng.className}
                                style={{ fontWeight: i18n.resolvedLanguage === lng.nativeName ? 'bold' : 'normal' }}
                                type="submit"
                                onClick={() => i18n.changeLanguage(lng.nativeName)}
                            >
                                <img
                                    key={lng.nativeName}
                                    src={lng.icon}
                                    alt={lng.nativeName}
                                />
                            </button>
                        </Tooltip>
                    ))}
                </div>
                <Form.Text
                    style={{ color: '#b1b1b1' }}
                    className="loginForm_back_text-muted"
                    onClick={handleHomePage}>
                    <i className="far fa-arrow-alt-circle-left"></i>
                    {t('buttons.backToHomePage')}
                </Form.Text>
            </BoxContainer>
        </AccountContext.Provider>
    );
};

export default LoginPage;