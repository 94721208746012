import React from 'react';
import { Grid } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
import ModalTable from '../../../../components/table/modalTable';
import './allInvoicesModal.css';

interface Props {
    show: boolean;
    onHide: () => void;
    modalClose: any;
};

export default function AllInvoiceModal({
    show,
    onHide,
    modalClose
}: Props) {
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='lg'
                dialogClassName="allInvoicesModal_modal"
            >
                <Modal.Header
                    style={{ background: "#849BA4" }}
                    closeButton
                    className="allInvoices_dashboard-header"
                >
                    <Modal.Title className="modal_title">All Invoices</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ModalTable />
                </Modal.Body>
                <Modal.Footer>
                    <Grid item xs={12}>
                        <button
                            className="allInvoicesModal_dashboard-closeButton"
                            onClick={modalClose}
                            style={{float: "left"}}
                        >
                            Close
                        </button>
                        <button
                            className="allInvoicesModal_dashboard-saveButton"
                            onClick={modalClose}
                            style={{float: "right"}}
                        >
                            Save
                        </button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    );
};