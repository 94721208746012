import React from 'react';
import { Modal } from 'react-bootstrap';
import MenageTableDataAccordion from '../../menageTableAccordion/menageTableAccordion';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

interface Props {
    show: boolean;
    onHide: () => void;
    modalClose: any;
};

export default function ManageTableDataModal({
    show,
    onHide,
    modalClose,
}: Props) {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='lg'
                dialogClassName="allInvoicesModal_modal"
            >
                <Modal.Header
                    closeButton
                    className="allInvoices_dashboard-header"
                    style={{ background: "#849BA4" }}
                >
                    <Modal.Title className="modal_title">{t('columnsModal.manageColumns')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <MenageTableDataAccordion />
                </Modal.Body>
                <Modal.Footer>
                    <Grid item xs={12}>
                        <button
                            className="allInvoicesModal_dashboard-closeButton"
                            onClick={modalClose}
                            style={{float: "left"}}
                        >
                            {t('buttons.close')}
                        </button>
                        <button
                            className="allInvoicesModal_dashboard-saveButton"
                            onClick={modalClose}
                            style={{float: "right"}}
                        >
                            {t('buttons.save')}
                        </button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    );
};