/* eslint-disable react-hooks/exhaustive-deps */
import React,
{
    useState,
    useRef,
    useEffect,
    ReactNode,
    ReactElement
} from 'react';
import {
    Easing,
    Tween,
    update
} from '@tweenjs/tween.js';

export default function GoogleMap({
    center,
    zoom,
    children
}: {
    center: google.maps.LatLngLiteral,
    zoom: number,
    children: ReactNode
}) {
    const ref = useRef<HTMLDivElement>();
    const [map, setMap] = useState<google.maps.Map | null>(null);
    const [tween, setTween] = useState<Tween<any>>();

    const style = {
        height: "600px"
    };

    function Animate(time: number) {
        requestAnimationFrame(Animate);
        update(time)
    }
    requestAnimationFrame(Animate)

    useEffect(() => {
        setMap(new window.google.maps.Map(ref.current!, {
            mapId: "8cb61d00b781ebd8",
            center: { lat: 45.250375064551505, lng: 19.83732959049245 },
            zoom: 0
        }))
    }, []);

    useEffect(() => {
        if (map) {
            if (tween) {
                tween.stop();
            }
            const from = {
                center: map.getCenter().toJSON(),
                zoom: map.getZoom()
            };
            setTween(new Tween(from)
                .to({ center, zoom }, 5000)
                .easing(Easing.Quadratic.Out)
                .onUpdate(({ center, zoom }) => {
                    map.moveCamera({ center, zoom });
                }).start());
        }
    }, [map, center, zoom])


    return (
        <div ref={ref} id="map" style={style}>
            {React.Children.map(children, (child: ReactElement) =>
                React.cloneElement(child, { map }))}
        </div>
    )
}