import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Modal } from 'react-bootstrap';
import { Grid } from '@material-ui/core';
import CustomSelect from '../../../components/customSelect/customSelect';

interface Props {
    show: boolean;
    onHide: () => void;
    modalClose: any;
}

export default function ManageDocumentTypeModal({
    show,
    onHide,
    modalClose
}: Props) {
    const { t } = useTranslation();
    return (
        <>
            <Modal
                show={show}
                onHide={onHide}
                size='sm'
            >
                <Modal.Header closeButton className="monthInvoices_dashboard-header" style={{ background: "#849BA4" }}>
                    <Modal.Title className="modal_title">Manage Document Types</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ borderRadius: '40px' }}>
                    <Grid item xs={12}>
                        <CustomSelect placeholder='' options={null} />
                    </Grid>
                    <Grid item xs={12} className="mt-3">
                        <FormControl
                            placeholder="Type"
                        />
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Grid item xs={12}>
                        <button
                            className="monthModal_dashboard-closeButton"
                            onClick={modalClose}
                            style={{ float: "left" }}
                        >
                            {t('buttons.close')}
                        </button>
                        <button
                            className="monthModal_dashboard-saveButton"
                            onClick={modalClose}
                            style={{ float: "right" }}
                        >
                            {t('buttons.save')}
                        </button>
                    </Grid>
                </Modal.Footer>
            </Modal>
        </>
    );
};