import styled from 'styled-components';

export const Headers = styled.header`
  display: flex;
  opacity: 0.9;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5rem;
  background-color: #1a1d1e;
  position: relative;
  z-index: 500;
  
  @media only Screen and (max-width: 1024px) {
    padding: 0.5rem 3rem;
    background: #1a1d1e;
    height: 100px;
    padding: 25px;
  }
  @media only Screen and (max-width: 640px) {
    padding: 0.5rem 1.5rem;
    background: #1a1d1e;
    padding: 25px;
    height: 100px
  }
  @media only Screen and (max-width: 400px) {
   padding: 25px;
   height: 100px
  }
`;
export const LogoSection = styled.a`
  display: flex;
  align-items: center;
  width: 2rem;
  height: auto;
  cursor: pointer;
  img {
    margin-right: 0.5rem;
    width: 200px;
  }

  @media only Screen and (max-width:1334px){
    display: inline-block;
    display: none;
}
@media only Screen and (max-width: 768px){
  display: inline-block;
}
@media only Screen and (max-width: 768px){
  display: inline-block;
}
`;
export const Nav = styled.nav`
        width: 100%;
        margin: auto;
        max-width: 50rem;
        display: flex;
        align-items: center;
        opacity: 1;
        justify-content: space-between;
        color: #fff;
        text-align: center;
        z-index: 1;
        font-size: 18px;
        a{
          color: #fff;
        }
        @media only Screen and (max-width:768px){
            display: none;
        }
        a{
            font-weight: 500;
            color: #fff;
            line-height: 1.5;
            text-decoration: none;
            &:not(:last-child):hover::after{
                width: 100%;
                background: #8a9aa2; 
             }
        }
    `;
export const Button = styled.button`
  background-color: #849BA4;
  border: 1px solid #849BA4;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    transform: scale(0.9);
  }
  @media only Screen and (max-width: 40em) {
    font-size: 1.2rem;
    &:hover {
      transform: none;
    }
    &:focus {
      transform: none;
    }
  }
`;
export const HamburgerBtn = styled.button`
position: relative;
border: none;
background: transparent;
width: 2rem;
height: 3px;
cursor: pointer;
display: none;

@media only Screen and (max-width:48em){
    display: inline-block;
}
&::before,
&::after{
    content: "";
    background-color: #ffff;
    width: 2.5rem;
    height: 2px;
    display: inline-block;
    position: absolute;
    left: 0;
    cursor: pointer;
    transition: all 0.3s;
    margin-top: 15px;
    margin-bottom: 15px;
}
&::before{
    top: ${props => (props.clicked ? "0" : "-0.5rem")};
    transform: ${props => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
}
&::after{
    top: ${props => (props.clicked ? "0" : "0.5rem")};
    transform: ${props => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
}
`;
export const MobileMenu = styled.nav`
        display: flex;
        flex-direction: column;
        background: #1a1d1e;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        opacity: ${props => (
    props.clicked ? "1" : 0
  )};
        visibility: ${props => (
    props.clicked ? "visible" : "hidden"
  )};
    border-radius: 20px;
    margin: 0.5rem;
    overflow-x: hidden;
    a{
            color: #fff;
            font-weight: 600;
            font-size: 1.5rem;
            margin: 1.5rem;
            cursor: pointer;
            text-decoration: none;
        }
`;