import React from "react";
import { CssBaseline, ThemeProvider } from "@material-ui/core";
import { Row, Container } from "react-bootstrap";
import AppTheme from "./AppTheme";

interface Props {
    children?: React.ReactNode;
};

export default function Layout({ children }: Props) {
    const theme = AppTheme();
    return (
        <ThemeProvider theme={theme}>
            <React.Fragment>
                <CssBaseline />
                <Container fluid>
                    <div>
                        <Row id="row-layout">{children}</Row>
                    </div>
                </Container>
            </React.Fragment>
        </ThemeProvider>
    );
};