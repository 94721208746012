import React from 'react';
import {
    Grid,
    Typography,
    makeStyles,
    createStyles,
    Theme
} from '@material-ui/core';
import img1 from '../../../images/rightBlock.png';
import clientFirst from '../../../images/clientFirst.jpg';
import strongExperts from '../../../images/strongExperts.jpg';
import delivery from '../../../images/delivery.jpg'
import CardHomePage from '../../../components/cardHomePage/cardHomePage';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: "20px",
        },
        text: {
            marginTop: "20px"
        },
        whyUs_div: {
            alignContent: "center",
            justifyContent: "center",
            fontWeight: "bold"
        },
        img: {
            width: "100%",
            paddingRight: "20px",
            height: "auto",
            [theme.breakpoints.down('xs')]: {
                paddingRight: 0
            },
        }
    }),
);

export default function WhyUsSection() {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <Grid
            className={classes.root}
            container
            spacing={0}
        >
            <Grid
                container
                item
                sm={6}
                xs={12}
                alignContent="flex-start"
            >
                <Typography
                    variant='h3'
                    className={classes.whyUs_div}
                >
                    {t('homePage.whyUs')}
                </Typography>
                <Grid>
                    <Typography variant="h5" >
                        {t('homePage.whyUsContent1')}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.text}
                    >
                        {t('homePage.whyUsContent2')}
                    </Typography>
                    <Typography
                        variant="h5"
                        className={classes.text}
                    >
                        {t('homePage.whyUsContent3')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                item
                sm={6}
                xs={12}
            >
                <img
                    src={img1}
                    alt="content1"
                    className={classes.img}
                />
            </Grid>
            <Grid
                container
                spacing={0}
                className="contentSection_cards"
            >
                <Grid
                    item
                    sm={4}
                    xs={12}
                >
                    <CardHomePage
                        img={clientFirst}
                        title={t('cards.cardClientTitle')}
                        shortDesc={t('cards.cardClientShort')}
                        description={t('cards.cardClientDesc')}
                    />
                </Grid>
                <Grid
                    item
                    sm={4}
                    xs={12}
                >
                    <CardHomePage
                        img={strongExperts}
                        title={t('cards.cardStrongExpertiseTitle')}
                        shortDesc={t('cards.cardStrongExpertiseShort')}
                        description={t('cards.cardStrongExpertiseDesc')}
                    />
                </Grid>
                <Grid
                    item
                    sm={4}
                    xs={12}
                >
                    <CardHomePage
                        img={delivery}
                        title={t('cards.cardDeliveryOnTimeTitle')}
                        shortDesc={t('cards.cardDeliveryOnTimeShort')}
                        description={t('cards.cardDeliveryOnTimeDesc')}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};