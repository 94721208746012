import React from 'react';
import { 
    DialogActions, 
    Grid, 
    Typography, 
    Button,
    Dialog, 
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import webDesign from '../../../images/webDesign.png';
import { useTranslation } from 'react-i18next';
import './webDesignModal.css';

interface Props {
    open: any;
    modalClose: any;
}

export default function WebDesignModal({
    open,
    modalClose
}: Props) {
    const {t} = useTranslation();

    return (
        <div>
            <Dialog
                style={{ borderRadius: 2 }}
                open={open}
                onClose={modalClose}
                aria-labelledby="webDesignModal_dialog-title"
                aria-describedby="webDesignModal_dialog-description"
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="webDesignModal_modal_title"
                    >
                        <Grid
                            item
                            xs={1}
                            sm={1}
                        >
                            <img
                                src={webDesign}
                                alt=""
                                className="webDesignModal_modal_logo_img"
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <DialogTitle
                                id="webDesignModal_dialog-title"
                                className="webDesignModal_dialog_title">
                                <b>{t('homePage.webDesignTitle')}</b>
                            </DialogTitle>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="webDesignModal_modal_typography"
                            >
                                {t('homePage.webDesignWhatDesignerDoTitle')}
                            </Typography>
                            <DialogContentText
                                id="webDesignModal_dialog-description"
                                className="webDesignModal_dialog_content"
                            >
                               {t('homePage.webDesignWhatDesignerDoText')}

                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="webDesignModal_modal_typography"
                            >
                               {t('homePage.webDesignHowFastLearnTitle')}
                            </Typography>
                            <DialogContentText
                                id="webDesignModal_dialog-description"
                                className="webDesignModal_dialog_content"
                            >
                                {t('homePage.webDesignHowFastLearnText')}

                            </DialogContentText>
                            <DialogActions className="webDesignModal_modal_dialogActions">
                                <Button
                                    variant="outlined"
                                    className="webDesignModal_modal_button"
                                    onClick={modalClose}
                                >
                                    {t('buttons.close')}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </Dialog>
        </div >
    );
}
