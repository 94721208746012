import { useContext } from 'react';
import { AccountContext } from './accountContext';
import {
    BoxContainer,
    FormContainer,
    SubmitButton,
    Input,
} from './common';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

export function SignupForm(props: any) {
    const { t } = useTranslation();
    const { switchToSignin } = useContext(AccountContext);

    return (
        <BoxContainer>
            <FormContainer>
                <Input type="text" placeholder={t('signUp.inputFullName')} />
                <Input type="email" placeholder={t('signUp.inputEmail')} />
                <Input type="password" placeholder={t('signUp.inputPassword')} />
                <Input type="password" placeholder={t('signUp.inputConfirmPass')} />
            </FormContainer>
            <Form.Text
                className="loginForm_text-muted"
            >
                {t('signUp.forgetPass')}
            </Form.Text>
            <SubmitButton type="submit">{t('signUp.signUpBtn')}</SubmitButton>
            <Form.Text
                className=" loginForm_text-muted"
            >
                {t('signUp.mutedAlreadyHaveAcc')}
                <Form.Text
                    className="muted_bold"
                    onClick={switchToSignin}
                >
                    {t('logIn.logInBtn')}
                </Form.Text>
            </Form.Text>
        </BoxContainer>
    );
};