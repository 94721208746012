import React from 'react';
import { 
    DialogActions, 
    Grid, 
    Typography, 
    Button,
    Dialog, 
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@material-ui/core';
import database from '../../../images/database.png';
import { useTranslation } from 'react-i18next';
import './databaseModal.css';

interface Props {
    open: any;
    modalClose: any;
}

export default function DatabaseModal({
    open,
    modalClose
}: Props) {
    const {t} = useTranslation();
    return (
        <div>
            <Dialog
                style={{ borderRadius: 2 }}
                open={open}
                onClose={modalClose}
                aria-labelledby="databaseModal_dialog-title"
                aria-describedby="databaseModal_dialog-description"
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="databaseModal_modal_title"
                    >
                        <Grid
                            item
                            xs={1}
                            sm={1}
                        >
                            <img
                                src={database}
                                alt=""
                                className="databaseModal_modal_logo_img"
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <DialogTitle
                                id="databaseModal_dialog-title"
                                className="databaseModal_dialog_title"
                            >
                                <b>{t('homePage.databaseTitle')}</b>
                            </DialogTitle>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid
                            item
                            sm={12}
                        >
                            <DialogContentText
                                id="databaseModal_dialog-description"
                                className="databaseModal_dialog_content"
                            >
                                {t('homePage.databaseGeneral')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="databaseModal_modal_typography"
                            >
                                {t('homePage.databaseSQLServerTitle')}
                            </Typography>
                            <DialogContentText
                                id="databaseModal_dialog-description"
                                className="databaseModal_dialog_content"
                            >
                                {t('homePage.databaseSQLServerText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="databaseModal_modal_typography"
                            >
                                {t('homePage.databasePostgreSQLTitle')}
                            </Typography>
                            <DialogContentText
                                id="databaseModal_dialog-description"
                                className="databaseModal_dialog_content"
                            >
                                {t('homePage.databasePostgreSQLText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="databaseModal_modal_typography"
                            >
                                {t('homePage.databaseCosmoDBTitle')}
                            </Typography>
                            <DialogContentText
                                id="databaseModal_dialog-description"
                                className="databaseModal_dialog_content"
                            >
                                {t('homePage.databaseCosmoDBText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="databaseModal_modal_typography"
                            >
                                {t('homePage.databaseMongoDBTitle')}
                            </Typography>
                            <DialogContentText
                                id="databaseModal_dialog-description"
                                className="databaseModal_dialog_content"
                            >
                                {t('homePage.databaseMongoDBText')}
                            </DialogContentText>
                            <DialogActions className="databaseModal_modal_dialogActions">
                                <Button
                                    variant="outlined"
                                    className="databaseModal_modal_button"
                                    onClick={modalClose}
                                >
                                    {t('buttons.close')}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </Dialog>
        </div >
    );
}
