/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useEffect, useRef, useState } from "react";
import Logo from "../../../images/Logo.png";
import {
  smartInvoicing as smartInvoicingRoute,
  loginPage as loginPageRoute,
  aboutUsPage as aboutUsPageRoute,
  homePage as homePageRoute,
  contactPage as contactPageRoute,
  learningPage as learningPageRoute,
  dashboard as dashboardRoute
}
  from "../../../app/routes";
import {
  Headers,
  HamburgerBtn,
  LogoSection,
  MobileMenu,
  Nav
}
  from "./headerStyle";
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Tooltip } from "@material-ui/core";
import rsIcon from "../../../images/serbia.png";
import ukIcon from "../../../images/uk.png";
import { HashLink as Link } from 'react-router-hash-link'
import './header.css';

const Header = () => {
  const history = useHistory();
  const lngs1 = [
    { nativeName: 'en', className: 'language_switch_header', icon: ukIcon },
    { nativeName: 'rs', className: 'language_switch_header', icon: rsIcon }
  ];
  const { t, i18n } = useTranslation();
  const [click, setClick] = useState(false);

  const ref = useRef(null);
  gsap.registerPlugin(ScrollTrigger);

  const handleSmartInvoicing = () => {
    history.push(smartInvoicingRoute());
  };
  const handleLoginPage = () => {
    history.push(loginPageRoute());
  };
  const handleAboutUsPage = () => {
    history.push(aboutUsPageRoute())
  };
  const handleHomePage = () => {
    history.push(homePageRoute())
  };
  const handleContactPage = () => {
    history.push(contactPageRoute())
  };
  const handleLearningPage = () => {
    history.push(learningPageRoute())
  };

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    const element = ref.current;

    const mq = window.matchMedia("(max-width: 40em)");
    if (mq.matches) {
      gsap.to(element, {
        position: "fixed",
        top: "0",
        left: "0",
        right: "0",
        padding: "1rem 1.5rem",
        duration: 1,
        ease: "power1.out",
        scrollTrigger: {
          trigger: element,
          start: "bottom+=200 top",
          end: "+=100",
          scrub: true,
        },
      });
    } else {
      gsap.to(element, {
        position: "fixed",
        backgroundColor: "#1a1d1e",
        color: "#fff",
        duration: 1,
        ease: "power1.out",
        scrollTrigger: {
          trigger: element,
          start: "bottom+=250 top",
          end: "+=250",
          scrub: true,
        },
      });
    }
  }, []);

  return (
    <Headers ref={ref}>
      <LogoSection>
        <img src={Logo} alt="CodeBucks" onClick={handleHomePage} />
      </LogoSection>
      <Nav>
        <a href="#" className="link" onClick={handleHomePage}>{t('header.home')}</a>
        <a href="#" className="link" onClick={handleSmartInvoicing}>{t('header.smartInvoicing')}</a>
        <a href="#" className="link" onClick={handleLearningPage}>{t('header.learning')}</a>
        <Link to="#aboutUsSection" className="link">{t('header.aboutUs')}</Link>
        <a href="#" className="link" onClick={handleContactPage}>{t('header.contactUs')}</a>
        <div>
          {lngs1.map((lng) => (
            <Tooltip key={lng.nativeName} title={lng.nativeName === 'en' ? 'UK' : 'RS'} placement="bottom">
              <button
                className={lng.className}
                key={lng.nativeName}
                style={{ fontWeight: i18n.resolvedLanguage === lng.nativeName ? 'bold' : 'normal' }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng.nativeName)}
                id="language_button"
              >
                <img key={lng.nativeName} src={lng.icon} alt={lng.nativeName} />
              </button>
            </Tooltip>
          ))}
        </div>
      </Nav>
      <HamburgerBtn
        clicked={click}
        onClick={() => setClick(!click)}
      >
        <span></span>
      </HamburgerBtn>
      <MobileMenu clicked={click}>
        <a href="#" className="link" onClick={handleHomePage}>{t('header.home')}</a>
        <a href="#" className="link" onClick={handleSmartInvoicing}>{t('header.smartInvoicing')}</a>
        <a href="#" className="link" onClick={handleLearningPage}>{t('header.learning')}</a>
        <Link to="#aboutUsSection" className="link">{t('header.aboutUs')}</Link>
        <a href="#" className="link" onClick={handleContactPage}>{t('header.contactUs')}</a>
        <div className="mt-3">
          {lngs1.map((lng) => (
            <Tooltip key={lng.nativeName} title={lng.nativeName === 'en' ? 'UK' : 'RS'} placement="bottom">
              <button
                className={lng.className}
                key={lng.nativeName}
                style={{ fontWeight: i18n.resolvedLanguage === lng.nativeName ? 'bold' : 'normal' }}
                type="submit"
                onClick={() => i18n.changeLanguage(lng.nativeName)}
              >
                <img key={lng.nativeName} src={lng.icon} alt={lng.nativeName} />
              </button>
            </Tooltip>
          ))}
        </div>
      </MobileMenu>
    </Headers>
  );
};

export default Header;