import React from 'react';
import Footer from '../../footer/footer';
import Header from '../../navigations/header/header';
import ContactUsSection from './contactUsSection';

export default function ContactPage() {
    return (
        <>
            <Header />
            <ContactUsSection />
            <Footer />
        </>
    );
};