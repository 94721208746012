import React from 'react';
import { 
    DialogActions, 
    Grid, 
    Typography, 
    Button,
    Dialog, 
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import webApplication from '../../../images/webApplication.png';
import { useTranslation } from 'react-i18next';
import './webApplicationModal.css';

interface Props {
    open: any;
    modalClose: any;
}

export default function WebApplicationModal({
    open,
    modalClose
}: Props) {
    const {t} = useTranslation();

    return (
        <div>
            <Dialog
                style={{ borderRadius: 2 }}
                open={open}
                onClose={modalClose}
                aria-labelledby="webApplication_dialog-title"
                aria-describedby="webApplication_dialog-description"
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="webApplication_modal_title"
                    >
                        <Grid
                            item
                            xs={1}
                            sm={1}
                        >
                            <img
                                src={webApplication}
                                alt=""
                                className="webApplication_modal_logo_img"
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <DialogTitle
                                id="webApplication_dialog-title"
                                className="webApplication_dialog_title"
                            >
                                <b>{t('homePage.webApplicationTitle')}</b>
                            </DialogTitle>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="webApplication_modal_typography"
                            >
                                {t('homePage.webApplicationExampleTitle')}
                            </Typography>
                            <DialogContentText
                                id="webApplication_dialog-description"
                                className="webApplication_dialog_content"
                            >
                                {t('homePage.webApplicationExampleText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="webApplication_modal_typography"
                            >
                                {t('homePage.webApplicationBegginersTitle')}
                            </Typography>
                            <DialogContentText
                                id="webApplication_dialog-description"
                                className="webApplication_dialog_content"
                            >
                                {t('homePage.webApplicationBegginersText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="webApplication_modal_typography"
                            >
                                {t('homePage.webApplicationYouTubeTitle')}
                            </Typography>
                            <DialogContentText
                                id="webApplication_dialog-description"
                                className="webApplication_dialog_content"
                            >
                                {t('homePage.webApplicationYouTubeText')}

                            </DialogContentText>
                            <DialogActions className="webApplication_modal_dialogActions">
                                <Button
                                    variant="outlined"
                                    className="webApplication_modal_button"
                                    onClick={modalClose}
                                >
                                    {t('buttons.close')}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </Dialog>
        </div >
    );
}
