import styled from 'styled-components';

export const HamburgerBtnInvoice = styled.button`
        position: relative;
        border: none;
        background: transparent;
        width: 2rem;
        height: 3px;
        cursor: pointer;
        display: none;

        @media only Screen and (max-width:48em){
            display: inline-block;
        }
        &::before,
        &::after{
            content: "";
            background-color: #000;
            width: 2rem;
            height: 2px;
            display: inline-block;
            position: absolute;
            left: 0;
            cursor: pointer;
            transition: all 0.3s;
            margin-top: 15px;
        }
        &::before{
            top: ${props => (props.clicked ? "0" : "-0.5rem")};
            transform: ${props => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
        }
        &::after{
            top: ${props => (props.clicked ? "0" : "0.5rem")};
            transform: ${props => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
        }
    `;

export const MobileMenuInvoice = styled.nav`
        display: flex;
        flex-direction: column;
        background: #1a1d1e;
        align-items: center;
        justify-content: center;
        padding: 2rem 0;
        position: absolute;
        top: 200%;
        left: 0;
        right: 0;
        opacity: ${props => (
        props.clicked ? "1" : 0
    )};
        visibility: ${props => (
        props.clicked ? "visible" : "hidden"
    )};
    border-radius: 20px;
    margin: 0.5rem;
    overflow-x: hidden;
    a{
            color: #fff;
            font-weight: 600;
            font-size: 1.5rem;
            margin: 1.5rem;
            cursor: pointer;
            text-decoration: none;
        }
`;