/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import {
    dashboard as dashboardRoute,
    profile as profileRoute,
    homePage as homePageRoute,
    addInvoice as addInvoiceRoute,
    passwordChange as passwordChangeRoute,
    addClient as addClientRoute,
    template as templateRoute
}
    from "../../app/routes";
import Logo from "../../images/Logo.png";
import { HiTemplate } from 'react-icons/hi';

export default function DashboardSidebar() {
    const { t } = useTranslation();
    const history = useHistory();

    const handleProfilePage = () => {
        history.push(profileRoute())
    };

    const handleMainDashboard = () => {
        history.push(dashboardRoute())
    };

    const handleHomepage = () => {
        history.push(homePageRoute())
    };

    const handleAddInvoicePage = () => {
        history.push(addInvoiceRoute())
    };

    const handlePasswordChange = () => {
        history.push(passwordChangeRoute())
    };

    const handleAddClient = () => {
        history.push(addClientRoute())
    };

    const handleTemplate = () => {
        history.push(templateRoute())
    };

    return (
        <ul>
            <li>
                <a href="#">
                    <img src={Logo} alt="CodeBucks" className="dasboard_logo" />
                </a>
            </li>
            <li>
                <a href="#" onClick={handleMainDashboard}>
                    <span className="dashboard_icon"><i className="fas fa-home dashboard_fas"></i></span>
                    <span className="dashboard_title">{t('dashboard.dashboardSide')}</span>
                </a>
            </li>
            <li>
                <a href="#" onClick={handleAddInvoicePage}>
                    <span className="dashboard_icon"><i className="fas fa-file-alt"></i></span>
                    <span className="dashboard_title">{t('dashboard.addInvoice')}</span>
                </a>
            </li>
            <li>
                <a href="#" onClick={handleAddClient}>
                    <span className="dashboard_icon"><i className="fas fa-user"></i></span>
                    <span className="dashboard_title">{t('dashboard.addClient')}</span>
                </a>
            </li>
            <li>
                <a href="#" onClick={handleTemplate}>
                    <span className="dashboard_icon"><HiTemplate style={{fontSize: '20px'}}/></span>
                    <span className="dashboard_title">{t('dashboard.template')}</span>
                </a>
            </li>
            <li>
                <a href="#" onClick={handleProfilePage}>
                    <span className="dashboard_icon"><i className="fas fa-users"></i></span>
                    <span className="dashboard_title">{t('dashboard.profileSide')}</span>
                </a>
            </li>
            <li>
                <a href="#" onClick={handlePasswordChange}>
                    <span className="dashboard_icon"><i className="fas fa-lock"></i></span>
                    <span className="dashboard_title">{t('dashboard.passwordSide')}</span>
                </a>
            </li>
            <li>
                <a href="#" onClick={handleHomepage}>
                    <span className="dashboard_icon"><i className="fas fa-sign-out-alt"></i></span>
                    <span className="dashboard_title">{t('dashboard.signOutSide')}</span>
                </a>
            </li>
        </ul>
    );
};