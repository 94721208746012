import React from 'react';
import { 
    DialogActions, 
    Grid, 
    Typography, 
    Button,
    Dialog, 
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box
} from '@material-ui/core';
import dotNet from '../../../images/dotNet.png';
import { useTranslation } from 'react-i18next';
import './dotNetModal.css';

interface Props {
    open: any;
    modalClose: any;
}

export default function DotNetModal({
    open,
    modalClose
}: Props) {
    const { t } = useTranslation();

    return (
        <Box borderRadius="20%">
            <Dialog
                style={{ borderRadius: "10%" }}
                open={open}
                onClose={modalClose}
                aria-labelledby="dotNetModal_dialog-title"
                aria-describedby="dotNetModal_dialog-description"
            >
                <Grid container>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        className="dotNetModal_modal_title"
                    >
                        <Grid
                            item
                            xs={1}
                            sm={1}
                        >
                            <img
                                src={dotNet}
                                alt=""
                                className="dotNetModal_modal_logo_img"
                            />
                        </Grid>
                        <Grid
                            item
                            sm={12}
                            xs={12}
                        >
                            <DialogTitle
                                id="dotNetModal_dialog-title"
                                className="dotNetModal_dialog_title"
                            >
                                <b>{t('homePage.cSharDifficultTitle')}</b>

                            </DialogTitle>
                        </Grid>
                    </Grid>
                    <DialogContent>
                        <Grid
                            item
                            sm={12}
                        >
                            <DialogContentText
                                id="dotNetModal_dialog-description"
                                className="dotNetModal_dialog_content"
                            >
                                {t('homePage.dotNetGeneral')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="dotNetModal_modal_typography"
                            >
                                {t('homePage.dotNetUsedForTitle')}
                            </Typography>
                            <DialogContentText
                                id="dotNetModal_dialog-description"
                                className="dotNetModal_dialog_content"
                            >
                                {t('homePage.dotNetUsedForText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="dotNetModal_modal_typography"
                            >
                                {t('homePage.dotNetPogrammingLanguageTitle')}
                            </Typography>
                            <DialogContentText
                                id="dotNetModal_dialog-description"
                                className="dotNetModal_dialog_content"
                            >
                                {t('homePage.dotNetPogrammingLanguageText')}
                            </DialogContentText>
                        </Grid>
                        <Grid
                            item
                            sm={12}
                        >
                            <Typography
                                variant="h6"
                                className="dotNetModal_modal_typography"
                            >
                                {t('homePage.dotNetEasyToLearnTitle')}
                            </Typography>
                            <DialogContentText
                                id="dotNetModal_dialog-description"
                                className="dotNetModal_dialog_content"
                            >
                                {t('homePage.dotNetEasyToLearnText')}
                            </DialogContentText>
                            <DialogActions className="dotNet_modal_dialogActions">
                                <Button
                                    variant="outlined"
                                    className="dotNetModal_modal_button"
                                    onClick={modalClose}
                                >
                                    {t('buttons.close')}
                                </Button>
                            </DialogActions>
                        </Grid>
                    </DialogContent>
                </Grid>
            </Dialog>
        </Box>
    );
}
