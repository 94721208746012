import React from 'react';
import {
    Button,
    Container,
    makeStyles,
    Theme,
    createStyles
} from '@material-ui/core';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import { useTranslation } from 'react-i18next';

interface Props {
    title: any;
    shortDesc: any;
    description: any;
    img: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            borderRadius: '20px',
            marginTop: "40px",
            [theme.breakpoints.up('xs')]: {
                alignContent: "center",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
            },
            boxShadow: "0 8px 40px -12px rgba(0,0,0,0.5)",
            "&:hover": {
                boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.5)"
            },
        },
        media: {
            height: 0,
            paddingTop: '56.25%',
            width: "100%",
            overflow: "hidden",
            backgroundColor: "#cccccc"
        },
        expand: {
            border: "2px solid #849AA3",
            borderRadius: "20px",
            width: "100%",
            background: "transparent",
            color: "#849AA3",
            transform: 'rotate(0deg)',
            transition: "all 0.8s",
            marginLeft: 'auto',
            '&:hover': {
                background: "#849AA3",
                color: "white"
            },
            [theme.breakpoints.up('xs')]: {
                color: "#849AA3",
                background: "transparent"
            },
        },
        expandOpen: {
            background: "transparent",
            color: "white",
            transform: 'rotate(360deg)',
            transition: "all 0.8s",
            [theme.breakpoints.up('xs')]: {
                color: "#849AA3",
                background: "transparent"
            },
        },
        avatar: {
            backgroundColor: red[500],
        }
    }),
);

export default function RecipeReviewCard({
    title,
    shortDesc,
    description,
    img
}: Props) {
    const { t } = useTranslation();
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Container>
            <Card className={classes.root}>
                <CardHeader
                    title={title}
                />
                <CardMedia
                    className={classes.media}
                    image={img}
                    title="Paella dish"
                />
                <CardContent>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {shortDesc}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing>
                    <Button
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                    >
                        {t('cards.showMore')}
                    </Button>
                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <Typography paragraph>
                            {description}
                        </Typography>
                    </CardContent>
                </Collapse>
            </Card>
        </Container>
    );
}