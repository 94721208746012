import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import logo from '../../../images/UspravniLogo.png';
import { useDispatch, useSelector } from 'react-redux';
import { resetMessage, selectIsMessageSend, sendContactForm } from '../../../slices/emailSlice';
import EmailRequestModel from '../../../models/emailRequestModel';
import SnackbarModel from '../../../models/snackbarModel';
import { setSnackbar } from '../../../slices/snackbarSlice';
import ContactModel from '../../../models/contactModel';
import './contactUsSection.css';
import { Button } from '@material-ui/core';

export default function ContactUsSection() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [validated, setValidated] = useState(false);
    const [emailError, setEmailError] = useState("");
    const [success, setSuccess] = useState(false);
    const INIT_CONTACT_MODEL: ContactModel = {
        firstName: "",
        lastName: "",
        email: "",
        message: ""
    };

    const [newContact, setNewContact] = useState<ContactModel>(INIT_CONTACT_MODEL);

    var emailState = useSelector(selectIsMessageSend);

    const handleInputChange = (e: any) => {
        const name = e.target.name;
        const value = e.target.value;
        const updateContact = { ...newContact };
        if (name === "FirstName") {
            updateContact.firstName = value;
        }
        if (name === "LastName") {
            updateContact.lastName = value;
        }
        if (name === "Email") {
            updateContact.email = value;
        }
        if (name === "Message") {
            updateContact.message = value;
        }
        setNewContact(updateContact);
    };

    const showMapClicked = () => {
        window.open("https://www.google.com/maps/place/Midenas+d.o.o./@45.2503054,19.8351689,17z/data=!3m1!4b1!4m5!3m4!1s0x475b0d32863fccdf:0x8cb200ca18497379!8m2!3d45.2503054!4d19.8373576?hl=sr")
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
        }
        setValidated(true);
        var data: EmailRequestModel = {
            action: "",
            type: "",
            idRequest: "d7953e2f-dceb-4ae2-ad1e-0644170b540f",
            contact: newContact
        };
        dispatch(sendContactForm(data));
    };

    useEffect(() => {
        if (emailState.isMessageSend === true) {
            setSuccess(true);
            setSuccess(false);
        }
    }, [emailState])

    useEffect(() => {
        if (emailState.isMessageSend === true) {
            const snackbarSuccess: SnackbarModel = {
                message: t('snackbar.successMessage'),
                severity: "success",
                show: true
            }
            dispatch(setSnackbar(snackbarSuccess));
            dispatch(resetMessage());
            setNewContact(INIT_CONTACT_MODEL);
            setEmailError("");
            setValidated(false);
        } else if (emailState.isMessageSend === false && validated === false) {
            const snackbarError: SnackbarModel = {
                message: emailState.errorMessage,
                severity: "error",
                show: true
            }
            dispatch(setSnackbar(snackbarError));
            dispatch(resetMessage());
        }
    }, [emailState])

    return (
        <div className="contactUsPage_container">
            <span className="contactUsPage_big-circle"></span>
            <img src={logo} alt="logo" className="contactUsPage_square" />
            <div className="contactUsPage_form">
                <div className="contactUsPage_contact-info">
                    <h3 className="contactUsPage_title">{t('contactUs.title')}</h3>
                    <p className="contactUsPage_text">{t('homePage.aboutUsContetn1')}</p>
                    <div className="contactUsPage_info">
                        <div className="contactUsPage_information">
                            <i className="fas fa-map-marked-alt" ></i>
                            <p
                                className="contactUsPage_info"
                                onClick={showMapClicked}
                            >
                                {t('homePage.contactAddressContent')}
                            </p>
                        </div>
                        <div className="contactUsPage_information ">
                            <i className="fas fa-envelope"></i>
                            <p className="contactUsPage_info">{t('contactUs.contactUsEmail')}</p>
                        </div>
                        <div className="contactUsPage_information">
                            <i className="fas fa-phone-square-alt"></i>
                            <p className="contactUsPage_info">{t('homePage.contactPhoneContent')}</p>
                        </div>
                    </div>
                    <div className="contactUsSection_social-media">
                        <p>{t('contactUs.socialMedia')}</p>
                        <div className="contactUsSection_social-icon">
                            <a href="https://www.facebook.com/midenasit">
                                <i className="fab fa-facebook-f contactUsSection_facebook" />
                            </a>
                            <a href="https://www.instagram.com/it_midenas/">
                                <i className="fab fa-instagram contactUsSection_instagram" />
                            </a>
                            <a href="https://www.linkedin.com/company/midenas-doo/about/">
                                <i className="fab fa-linkedin-in contactUsSection_linkedIn" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="contactUsPage_contact-form">
                    <span className="contactUsSection_circle one"></span>
                    <span className="contactUsSection_circle two"></span>
                    <Form
                        action="index.html"
                        className="contactUsSection_form"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit}
                    >
                        <div className="contactUsPage_input-container focus">
                            <Form.Control
                                placeholder={t('contactUs.firstName')}
                                name="FirstName"
                                className="contactUsSection_inputs"
                                type="text"
                                aria-describedby="inputGroupPrepend"
                                required
                                onChange={(e) => handleInputChange(e)}
                                value={newContact.firstName}
                            />
                            <Form.Control.Feedback className="formControl_feedback" type="invalid">
                                {t('contactUs.enterFirstName')}
                            </Form.Control.Feedback>
                        </div>
                        <div className="contactUsPage_input-container focus">
                            <Form.Control
                                name="LastName"
                                placeholder={t('contactUs.lastName')}
                                className="contactUsSection_inputs"
                                type="text"
                                aria-describedby="inputGroupPrepend"
                                required
                                onChange={(e) => handleInputChange(e)}
                                value={newContact.lastName}
                            />
                            <Form.Control.Feedback className="formControl_feedback" type="invalid">
                                {t('contactUs.enterLastName')}
                            </Form.Control.Feedback>
                        </div>
                        <div className="contactUsPage_input-container focus">
                            <Form.Control
                                name="Email"
                                placeholder={t('contactUs.email')}
                                color="white"
                                className="contactUsSection_inputs"
                                type="email"
                                aria-describedby="inputGroupPrepend"
                                required
                                onChange={(e) => handleInputChange(e)}
                                value={newContact.email}
                            />
                            <Form.Control.Feedback className="formControl_feedback" type="invalid">
                                {t('contactUs.enterEmail')}
                            </Form.Control.Feedback>
                        </div>
                        <div className="contactUsPage_input-container textarea focus">
                            <Form.Control
                                className="contactUsSection_inputs"
                                as="textarea"
                                rows={3}
                                placeholder={t('contactUs.message')}
                                name="Message"
                                onChange={(e) => handleInputChange(e)}
                                value={newContact.message}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {t('contactUs.enterMessage')}
                            </Form.Control.Feedback>
                        </div>
                        <Button
                            variant="contained"
                            onClick={(e) => handleSubmit(e)}
                            type="submit"
                            className="contactUsPage_btn"
                        >
                            {t('buttons.send')}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    );
};